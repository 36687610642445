import React, { type Ref, forwardRef } from "react";
import type { SVGProps } from "react";

export const EyeOpenIcon = forwardRef(
	(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={32}
				height={32}
				fill="none"
				viewBox="0 0 32 32"
				className={`icon ${props.className || ""}`}
				ref={ref}
				{...props}
			>
				<path
					stroke="currentColor"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M20.773 16A4.768 4.768 0 0 1 16 20.773 4.768 4.768 0 0 1 11.227 16 4.768 4.768 0 0 1 16 11.227 4.768 4.768 0 0 1 20.773 16"
				/>
				<path
					stroke="currentColor"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M16 27.027c4.706 0 9.093-2.774 12.147-7.574 1.2-1.88 1.2-5.04 0-6.92C25.093 7.733 20.707 4.96 16 4.96S6.906 7.733 3.853 12.533c-1.2 1.88-1.2 5.04 0 6.92 3.053 4.8 7.44 7.574 12.147 7.574"
				/>
			</svg>
		);
	},
);
