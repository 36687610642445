import React, { type Ref, forwardRef } from "react";
import type { SVGProps } from "react";

export const UserSquare2Icon = forwardRef(
	(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={32}
				height={32}
				fill="none"
				viewBox="0 0 32 32"
				className={`icon ${props.className || ""}`}
				ref={ref}
				{...props}
			>
				<path
					stroke="currentColor"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1.5}
					d="M23.674 28.025c-1.1.325-2.4.475-3.925.475h-7.5c-1.525 0-2.825-.15-3.925-.475.275-3.25 3.613-5.813 7.675-5.813 4.063 0 7.4 2.563 7.675 5.813"
				/>
				<path
					stroke="currentColor"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1.5}
					d="M19.752 3.5h-7.5c-6.25 0-8.75 2.5-8.75 8.75v7.5c0 4.725 1.425 7.312 4.825 8.275.275-3.25 3.613-5.813 7.675-5.813 4.063 0 7.4 2.563 7.675 5.813 3.4-.963 4.825-3.55 4.825-8.275v-7.5c0-6.25-2.5-8.75-8.75-8.75m-3.75 15.212a4.481 4.481 0 0 1-4.475-4.487c0-2.475 2-4.475 4.475-4.475 2.475 0 4.475 2 4.475 4.475 0 2.475-2 4.487-4.475 4.487"
				/>
				<path
					stroke="currentColor"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1.5}
					d="M20.477 14.225c0 2.475-2 4.488-4.475 4.488a4.481 4.481 0 0 1-4.475-4.488c0-2.475 2-4.475 4.475-4.475 2.475 0 4.475 2 4.475 4.475"
				/>
			</svg>
		);
	},
);
