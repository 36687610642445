import React, { type Ref, forwardRef } from "react";
import type { SVGProps } from "react";

export const CaretDownIcon = forwardRef(
	(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={32}
				height={32}
				fill="none"
				viewBox="0 0 32 32"
				className={`icon ${props.className || ""}`}
				ref={ref}
				{...props}
			>
				<path
					stroke="currentColor"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1.5}
					d="m22 13-6.5 5L9 13"
				/>
			</svg>
		);
	},
);
