import React, { useState } from "react";
import Form from "react-bootstrap/esm/Form";
import type { FormControlProps } from "react-bootstrap/esm/FormControl";
import InputGroup from "react-bootstrap/esm/InputGroup";
import { Exclaim } from "./Icons/Exclaim";
import { EyeCrossedIcon } from "./svg/icons/EyeCrossed";
import { EyeOpenIcon } from "./svg/icons/EyeOpen";
export interface FormGroupProps extends FormControlProps {
	name?: string;
	rows?: number;
	label?: string;
	success?: string;
	handleRef?: (v: HTMLInputElement) => void;
	errorText?: string;
	noLabel?: boolean;
	eyePostfix?: boolean;
	error?: string;
	isTextarea?: boolean;
	controlId?: string;
	autoComplete?: string;
	required?: boolean;
	labelClass?: string;
	classNameInput?: string;
	labelNote?: string;
}

const FormGroup = ({
	name,
	label,
	error,
	handleRef,
	isTextarea,
	eyePostfix,
	className,
	labelClass,
	errorText,
	success,
	noLabel,
	controlId,
	classNameInput = "",
	labelNote,
	type,
	onBlur,
	onFocus,
	...rest
}: FormGroupProps) => {
	const cls = `bg-color-white ${classNameInput}`;
	const [focus, setFocus] = useState(false);
	const [showContent, setShowContent] = useState(false);

	const handleFocus = (e) => {
		setFocus(true);
		onFocus && onFocus(e);
	};

	const handleBlur = (e) => {
		setFocus(false);
		onBlur && onBlur(e);
	};

	const formGroup = (
		<Form.Control
			id={name}
			className={cls}
			as={isTextarea ? "textarea" : undefined}
			ref={handleRef}
			name={name}
			isInvalid={!!error}
			onFocus={handleFocus}
			onBlur={handleBlur}
			{...(eyePostfix ? { type: showContent ? "text" : "password" } : { type })}
			{...rest}
		/>
	);

	return (
		<Form.Group controlId={controlId} className={className}>
			{!noLabel && (
				<Form.Label
					htmlFor={name}
					className={labelClass ? labelClass : "tbodymd fw-medium mb-s10"}
					style={{ lineHeight: "160%" }}
				>
					{label}
					{labelNote ? (
						<span className="text-color-neutral500 tbodymd fw-normal ms-s4">
							{labelNote}
						</span>
					) : (
						""
					)}
				</Form.Label>
			)}
			{!eyePostfix && formGroup}
			{eyePostfix && (
				<InputGroup
					className={`${error ? "form-group-postfix-error" : "form-group-postfix"}`}
				>
					{formGroup}
					{eyePostfix && (
						<InputGroup.Text
							id="basic-addon"
							className={
								focus ? "focused bg-color-white pe-3" : "bg-color-white pe-3"
							}
						>
							<span
								className="cursor-pointer"
								onClick={() => setShowContent((prev) => !prev)}
							>
								{showContent ? (
									<EyeCrossedIcon role="button" width={20} height={20} />
								) : (
									<EyeOpenIcon role="button" width={20} height={20} />
								)}
							</span>
						</InputGroup.Text>
					)}
				</InputGroup>
			)}
			{error && (
				<Form.Control.Feedback
					type="invalid"
					style={{ display: "flex", alignItems: "center" }}
				>
					<Exclaim style={{ marginRight: "8px" }} />
					{error}
				</Form.Control.Feedback>
			)}
		</Form.Group>
	);
};

export default FormGroup;
