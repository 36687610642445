import React from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { Link } from "react-router-dom";
import { FacebookIcon } from "../../components/svg/icons/Facebook";
import { Linkedin2Icon } from "../../components/svg/icons/Linkedin2";
import { TwitterIcon } from "../../components/svg/icons/Twitter";
import { YoutubeLogoIcon } from "../../components/svg/icons/YoutubeLogo";
import { Logo } from "./Logo";

export interface IFooterProps {
	className?: string;
	noFooterTag?: boolean;
}

const Footer: React.FC<IFooterProps> = ({ noFooterTag, className = "" }) => {
	const socials = [
		{
			icon: (
				<Linkedin2Icon
					width={24}
					height={24}
					className="text-color-neutral900"
				/>
			),
			link: "https://www.linkedin.com/company/anasaea",
		},

		{
			icon: (
				<FacebookIcon
					width={24}
					height={24}
					className="text-color-neutral900"
				/>
			),
			link: "https://www.facebook.com/anasaea.facebook",
		},

		{
			icon: (
				<TwitterIcon width={24} height={24} className="text-color-neutral900" />
			),
			link: "https://twitter.com/anasaea_art",
		},

		{
			icon: (
				<YoutubeLogoIcon
					width={24}
					height={24}
					className="text-color-neutral900"
				/>
			),
			link: "https://www.youtube.com/@ANASAEA_official",
		},
	];

	const content = (
		<div className="m-auto max-width-1920">
			<Row className="gx-0">
				<Col xs={12} md={6}>
					<Logo className="text-color-neutral900" />
					<p className="mb-s0 tbodymd mt-s16">
						Buy, Sell, and Follow your Favourites Artists & Galleries
					</p>
				</Col>

				<Col xs={12} md={6} className="mt-s24 mt-md-s0">
					<div className="d-flex flex-column flex-md-row justify-content-md-end">
						<div style={{ width: "200px" }}>
							<p className="mb-s16 tbodymd fw-semibold">For Buyers</p>

							<Link
								target="_blank"
								className="text-color-neutral700 d-block tbodymd mb-s12"
								to="/artworks"
							>
								Artworks
							</Link>

							<Link
								target="_blank"
								className="text-color-neutral700 d-block tbodymd mb-s12"
								to="/exhibitions"
							>
								Exhibitions
							</Link>

							<Link
								target="_blank"
								className="text-color-neutral700 d-block tbodymd mb-s12"
								to="/artists"
							>
								Artists
							</Link>

							<Link
								target="_blank"
								className="text-color-neutral700 d-block tbodymd mb-s12"
								to="/all-products"
							>
								Products
							</Link>
						</div>

						<div
							className="mt-s24 mt-md-s0 ms-md-s24"
							style={{ width: "200px" }}
						>
							<p className="mb-s16 tbodymd fw-semibold">About ANASAEA</p>

							<a
								className="text-color-neutral700 d-block tbodymd mb-s12"
								href="https://blog.anasaea.com"
								target="_blank"
								rel="noreferrer"
							>
								About us
							</a>

							<a
								className="text-color-neutral700 d-block tbodymd mb-s12"
								href="https://blog.anasaea.com/museums"
								target="_blank"
								rel="noreferrer"
							>
								About Museums
							</a>

							<Link
								className="text-color-neutral700 d-block tbodymd mb-s12"
								to="/pricing"
								target="_blank"
							>
								Pricing
							</Link>

							<a
								className="text-color-neutral700 d-block tbodymd mb-s12"
								href="/terms-and-conditions.pdf"
								target="_blank"
								rel="noreferrer"
							>
								Terms of Service
							</a>

							<a
								className="text-color-neutral700 d-block tbodymd mb-s12"
								href="https://blog.anasaea.com/privacy-policy"
								target="_blank"
								rel="noreferrer"
							>
								Privacy Policy
							</a>
						</div>
					</div>
				</Col>
			</Row>

			<Row className="gx-0 border-top border-1 border-color-blackT10 pt-s24 mt-s24 mt-xxl-s100">
				<Col
					xs={12}
					xxl={6}
					className="d-flex align-items-center justify-content-start"
				>
					<p className="mt-s0 mb-s0 tbodymd fw-medium">Follow Us</p>
					<div className="ms-s30 d-flex align-items-center">
						{socials.map((s) => (
							<a
								key={s.link}
								href={s.link}
								className="me-s16"
								target="_blank"
								rel="noreferrer"
							>
								{s.icon}
							</a>
						))}
					</div>
				</Col>

				<Col
					xs={12}
					xxl={6}
					className="mt-s32 mt-xxl-s0 d-flex align-items-center justify-content-start justify-content-xxl-end"
				>
					<p className="mb-s0 tbodymd fw-medium">
						&copy;&nbsp;Copyright 2022-2023 Superstring Solutions AG
					</p>
				</Col>
			</Row>
		</div>
	);

	const containerCls = `bg-color-neutral50 py-s32 px-xxl-11 px-xl-6 px-3 pt-xxl-s96 ${className}`;

	return noFooterTag ? (
		<div className={containerCls}>{content}</div>
	) : (
		<footer className={containerCls}>{content}</footer>
	);
};

export default Footer;
