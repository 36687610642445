import { dateToString } from "../components/neumorphism/DatePicker";
import { LSKEY_LOGIN_TOKEN } from "./const";

export const addGetParamsToUrl = (
	url: string,
	params: Record<string, string>,
) => {
	const urlObj = new URL(url);

	for (const [key, value] of Object.entries(params)) {
		urlObj.searchParams.append(key, value);
	}
	return urlObj.toString();
};

export const getLinkIdentifierForGallery = (
	data: any,
	fallback: any = null,
) => {
	if (!data) {
		return fallback || null;
	}

	return data.nickname
		? data.nickname
		: data.galleryData
			? data.galleryData.nickname
				? data.galleryData.nickname
				: data._id
			: data._id;
};

export const getLinkIdentifierForArtist = (data: any, fallback: any = null) => {
	if (!data) {
		return fallback || null;
	}

	return data.nickname ? data.nickname : data._id;
};

type TWindowOpenParams = Parameters<typeof window.open>;
// safeWindowOpen
// Safari blocks window.open in async context, setTimeout is ok
export const windowOpen = (
	url: string,
	target: TWindowOpenParams[1] = "",
	features?: TWindowOpenParams[2],
) => {
	const link = document.createElement("a");
	link.href = url;
	link.target = target;
	link.click();
};

export const strValueToBoolean = (str: string) => {
	if (!str) return false;
	return str.toLowerCase() === "true";
};

export const getGalleryAccessTokenKey = (galleryId: string) => {
	if (!galleryId) {
		console.error("getGalleryAccessTokenKey", "no-id");
		return null;
	}
	return `anasaea_gal_ac_t_${galleryId}`;
};

export const getErrorMessage = (err: any) =>
	err && err.message ? err.message : "";

type TWithNames = {
	firstName: string;
	lastName?: string;
};

type TWithPic = {
	pictureURL?: string | null;
	picture: {
		thumb?: {
			url?: string | null;
		};

		pic1024?: {
			url?: string | null;
		};
	};
};

export const generateRandomToken = (seedString, length) => {
	const characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	const seed = seedString.charCodeAt(0) % characters.length;
	let token = "";

	for (let i = 0; i < length; i++) {
		const randomNumber = Math.floor(Math.random() * characters.length);
		const charIndex = (randomNumber + seed) % characters.length;
		token += characters.charAt(charIndex);
	}

	return token;
};

export const formatPricingValue = (v: number | string) => {
	const num = +v;
	return num.toFixed(2); //.replace('.', ',');
};

export const getOwnerName = (data: object | null | undefined) => {
	if (data?.galleryData) {
		return data.galleryData.name;
	} else if (data?.artistName) {
		return data.artistName;
	} else {
		return "";
	}
};
export const getFullUserName = (data: TWithNames | null | undefined) => {
	if (!data) return "";
	return `${data.firstName}${data.lastName ? ` ${data.lastName}` : ""}`;
};

export const getAvatar = (userData: TWithPic | null | undefined) => {
	return (
		userData.picture?.thumb?.url ||
		userData.picture?.pic1024?.url ||
		"/images/placeholders/artist_new.png"
	);
};
export const getUserEmail = (data: IUser | null | undefined) => {
	if (
		!data ||
		!data.emails ||
		!Array.isArray(data.emails) ||
		data.emails.length === 0
	) {
		return "";
	}

	if (!data.emails[0] || !data.emails[0].address) return "";
	return data.emails[0].address;
};
export const getShortUserInfo = (data: any) => {
	if (!data) return "";
	const info: string[] = [];
	if (data.dob && data.dobPublic) info.push(dateToString(data.dob));
	if (data.country) info.push(data.country);
	return info.join(", ") || "";
};

export const authUserTypeToAnalyticsUserType = (userType: string) => {
	if (!userType) {
		throw new Error(`Unknown user type. ${userType}`);
	}

	return {
		user: "collector",
		curator: "gallery",
		artist: "artist",
	}[userType];
};

export const fetchWithAuth = ({ url, data }) =>
	fetch(url, {
		body: JSON.stringify(data),
		headers: {
			Authorization: `Bearer ${localStorage.getItem(LSKEY_LOGIN_TOKEN)}`,
		},
	});
