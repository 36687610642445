import React, { useContext, useMemo } from "react";
import {
	ToastContainer,
	type ToastOptions,
	toast as toastify,
} from "react-toastify";

interface ToastContext {
	info: (message: string) => void;
	error: (message: string) => void;
}

const ToastContext = React.createContext<ToastContext>({
	info: (message: string) => message,
	error: (message: string) => message,
});

const getToastOptions = (className: string): ToastOptions => ({
	position: "top-right" as const,
	autoClose: 5000,
	hideProgressBar: true,
	closeOnClick: true,
	transition: undefined,
	theme: "colored",
	className,
});

export const ToastContextProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const ctx = useMemo<ToastContext>(
		() => ({
			info: (message: string) =>
				toastify.info(message, getToastOptions("atoast-info")),
			error: (message: string) =>
				toastify.error(message, getToastOptions("atoast-error")),
		}),
		[],
	);

	return (
		<ToastContext.Provider value={ctx}>
			<ToastContainer />
			{children}
		</ToastContext.Provider>
	);
};

export const useToastContext = () => useContext(ToastContext);
