import React, { useState } from "react";

export const ProfileContext = React.createContext(null as unknown as any);

export interface IProfileContextProviderProps {
	children?: React.ReactNode;
}

export const ProfileContextProvider: React.FC<IProfileContextProviderProps> = ({
	children,
}) => {
	const [showGettingStartedModal, setShowGettingStartedModal] = useState(false);
	const [showArtistModal, setShowArtistdModal] = useState(false);
	const [showGalleryModal, setShowGallerydModal] = useState(false);
	const [showArtModal, setShowArtdModal] = useState(false);
	const [showExhibitionModal, setShowExhibitionModal] = useState(false);
	const [showOnboardingModal, setShowOnboardingModal] = useState(false);
	const [onboardingStep, setOnboardingStep] = useState(0);
	const [showPurchaseModal, setShowPurchaseModal] = useState(false);

	const value = {
		showGettingStartedModal,
		setShowGettingStartedModal,
		showArtistModal,
		setShowArtistdModal,
		showArtModal,
		setShowArtdModal,
		showExhibitionModal,
		setShowExhibitionModal,
		showOnboardingModal,
		setShowOnboardingModal,
		showPurchaseModal,
		setShowPurchaseModal,
		showGalleryModal,
		setShowGallerydModal,
		onboardingStep,
		setOnboardingStep,
	};

	return (
		<ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
	);
};
