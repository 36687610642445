import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export interface IRouteWrapperProps {
	component: React.ComponentType<any>;
	layout: React.ComponentType<any>;
}

export type TAnyProps = {
	[props: string]: any;
};

export function RouteWrapper({
	component,
	layout,
	...rest
}: IRouteWrapperProps & TAnyProps) {
	const isLoggingIn = false;
	const Component = component;
	const Layout = layout;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const Comp = (props) => {
		if (isLoggingIn) return null;

		const comp = <Component {...props} />;

		return Layout ? <Layout {...props}>{comp}</Layout> : comp;
	};

	return <Comp />;
}

export function SuspenseRouteWrapper({
	component,
	layout,
}: {
	component: IRouteWrapperProps["component"];
	layout: IRouteWrapperProps["layout"] | false;
}) {
	const location = useLocation();
	const isLoggingIn = false;
	const Component = component;
	const Layout = layout;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const Comp = (props) => {
		if (isLoggingIn) return null;

		const comp = <Component {...props} />;

		return Layout ? <Layout {...props}>{comp}</Layout> : comp;
	};

	return (
		<React.Suspense fallback={null} key={location.pathname}>
			<Comp />
		</React.Suspense>
	);
}
