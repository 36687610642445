import React, { type Ref, forwardRef } from "react";
import type { SVGProps } from "react";

export const CubeIcon = forwardRef(
	(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={32}
				height={32}
				fill="none"
				viewBox="0 0 32 32"
				className={`icon ${props.className || ""}`}
				ref={ref}
				{...props}
			>
				<path
					stroke="currentColor"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1.5}
					d="M28 22.165V9.835a1 1 0 0 0-.51-.872l-11-6.187a1 1 0 0 0-.98 0l-11 6.187a1 1 0 0 0-.51.872v12.33a1 1 0 0 0 .51.872l11 6.187a1 1 0 0 0 .98 0l11-6.187a1 1 0 0 0 .51-.872"
				/>
				<path
					stroke="currentColor"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1.5}
					d="M27.862 9.328 16.12 16 4.139 9.327M16.12 16l-.118 13.353"
				/>
			</svg>
		);
	},
);
