import React, { useEffect } from "react";
import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/esm/Modal";
import { v2api } from "../../../actions/v2api";
import FormGroup from "../../../components/FormGroup";
import { NewSelect } from "../../../components/NewSelect";
import { useToastContext } from "../../../contexts/Toast";
import { useForm } from "../../../hooks/useForm";
import { RequiredRule2 } from "../../../utils/validation";

export interface IEditListModalProps {
	isOpen: boolean;
	data?: null | {
		name: string | null;
		description?: string | null;
		visibility: "private" | "public" | "unlisted";
	};
	onClose: () => void;
	onSuccess: () => void;
}

export interface IEditListModalForm {
	name: string;
	description?: string;
	isEdit?: boolean;
	visibility: "public" | "private" | "unlisted";
}

const PRIVACY_OPTIONS = [
	{
		value: "public",
		label: "Public",
	},
	{
		value: "private",
		label: "Private",
	},
	{
		value: "unlisted",
		label: "Access with link",
	},
];

export const EditListModal: React.FC<IEditListModalProps> = ({
	isOpen = false,
	data,
	isEdit,
	onClose,
	onSuccess,
}) => {
	const toast = useToastContext();
	const title = data ? "Edit Collection" : "Create Collection";
	const description = data
		? "Change information about your collection"
		: "Add information about your collection";

	const submit = (values: IEditListModalForm) => {
		console.log("submit", values);
		if (!isEdit) {
			v2api("/user-list/create", values, (err) => {
				console.log("create response");
				if (err && err.message) {
					console.error("Error during creating list", err.message);
					toast.error(err.message);
				} else {
					onSuccess();
				}
			});
		} else if (data) {
			v2api("/user-list/edit", { userListId: data._id, ...values }, (err) => {
				console.log("edit response");
				if (err && err.message) {
					console.error("Error during editing list", err.message);
					toast.error(err.message);
				} else {
					onSuccess();
				}
			});
		} else {
			console.error("No data for edit mode");
		}

		return null;
	};

	const { onSubmit, getFieldProps, setFieldValue, setValues } =
		useForm<IEditListModalForm>({
			onSubmit: submit,
			initialValues: {
				name: data?.name || "",
				description: data?.description,
				visibility: (data?.visibility || "public") as const,
			},
			validationSchema: {
				name: [RequiredRule2("Collection name is required")],
				visibility: [RequiredRule2("Privacy settings is required")],
			},
		});

	useEffect(() => {
		setValues({
			name: data?.name || "",
			description: data?.description,
			visibility: (data?.visibility || "public") as const,
		});
	}, [data]);
	const nameField = getFieldProps("name");
	const descriptionField = getFieldProps("description");

	return (
		<Modal
			centered
			show={isOpen}
			onHide={onClose}
			className="add-to-list-modal"
		>
			<div className="p-s36">
				<div>
					<p className="thead5 fw-semibold mb-s0">{title}</p>
					<p className="mt-s8 tbodymd fw-medium mb-s0 text-color-neutral600">
						{description}
					</p>
				</div>

				<FormGroup
					className="mt-s36"
					classNameInput="btn-lg"
					placeholder="Input name of collection"
					label="Name"
					{...nameField}
				/>

				<FormGroup
					as="textarea"
					rows={3}
					style={{ height: "98px" }}
					className="mt-s16"
					classNameInput="border-radius-s30 btn-lg"
					placeholder="Input description of collection"
					label="Description"
					{...descriptionField}
				/>

				<NewSelect
					label="Privacy"
					className="mt-s16"
					options={PRIVACY_OPTIONS}
					{...getFieldProps("visibility")}
				/>

				<div className="mt-s36 d-flex align-items-center justify-content-between">
					<Button
						variant="secondary"
						className="btn-lg w-50 me-s24"
						onClick={onClose}
					>
						Cancel
					</Button>

					<Button variant="primary" className="btn-lg w-50" onClick={onSubmit}>
						Save
					</Button>
				</div>
			</div>
		</Modal>
	);
};
