import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
	gaEventFreshLoad,
	gaEventVoidUserId,
} from "../actions/google-analytics";
import { useCurrentUserContext } from "./CurrentUser";

export interface IGlobalTrackerProps {
	children?: React.ReactNode;
}

export const GlobalTracker: React.FC<IGlobalTrackerProps> = ({ children }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { user, userId } = useCurrentUserContext();
	const loggingIn = false;

	useEffect(() => {
		if (user && !user.authFlowCompleted) {
			navigate("/signup", { authFlowContinue: 2 });
		}
	}, [user]);

	// Fresh loading tracker
	// Tracks userId per every fresh loading (page refresh, new tabs...etc every time spa reloaded)
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		if (!loggingIn && loading) {
			setLoading((prev) => {
				if (prev) {
					// indicates first time detection of user auth state
					gaEventFreshLoad({ userId: userId || undefined });
					return false;
				}

				return prev;
			});
		}
	}, [loading, loggingIn]);

	// Visit tracker
	// Tracks userId per every window.location.href change
	const [prevHref, setPrevHref] = useState(null);
	useEffect(() => {
		if (!loading) {
			if (prevHref !== window.location.href || prevHref === null) {
				gaEventVoidUserId({ userId: userId || undefined });
				setPrevHref(window.location.href);
			}
		}
	}, [loading, location, userId, prevHref]);

	return children;
};
