import React, { useCallback, useContext, useEffect, useState } from "react";
import { v2api } from "../actions/v2api";

export type TCurrentUserContext = {
	user: any;
	userId: any;
	refresh: (cb?: (res: any) => void) => void;
	loading: boolean;
	clear: () => void;
};

export const CurrentUserContext = React.createContext(
	null,
) as unknown as React.Context<TCurrentUserContext>;

export interface ICurrentUserProviderProps {
	children?: React.ReactNode;
}

export const CurrentUserContextProvider: React.FC<
	ICurrentUserProviderProps
> = ({ children }) => {
	const [touched, setTouched] = useState(false);
	const [loading, setLoading] = useState(false);
	const [fetched, setFetched] = useState(null);
	const [response, setResponse] = useState({
		user: null as any,
		userId: null as any,
	});

	const refresh = useCallback((cb: (res: any) => void = () => null) => {
		setLoading(true);
		v2api("/users/currentUserData", null, (err, res) => {
			setTouched(true);
			setLoading(false);
			if (res) {
				setFetched(res._id);
				setResponse({ user: res, userId: res._id });
			} else {
				setResponse({ user: null, userId: null });
			}

			if (cb && typeof cb === "function") cb(res);
		});
	}, []);

	const clear = useCallback(() => {
		setResponse({
			user: null,
			userId: null,
		});
	}, []);

	useEffect(() => {
		refresh();
	}, []);

	const ctx: TCurrentUserContext = {
		...response,
		userId:
			response && response?.user && response.user._id
				? response.user._id
				: null,
		refresh: refresh,
		clear: clear,
		loading: loading,
	};
	console.log("currentUserCtx", ctx);

	if (!touched && loading) {
		return null;
	}

	return (
		<CurrentUserContext.Provider value={ctx}>
			{children}
		</CurrentUserContext.Provider>
	);
};

export const useCurrentUserContext = () => useContext(CurrentUserContext);
