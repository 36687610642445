import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import type { TGalleryWithOwner } from "../../types";
import { useCurrentUserContext } from "../contexts/CurrentUser";
import { getErrorMessage } from "../utils";

import { v2api } from "../actions/v2api";
import type { TUserType } from "../utils/const";

export const useCurrentArtist = () => {
	const [artist, setArtist] = useState(null);
	const [loading, setLoading] = useState(false);
	const { user } = useCurrentUserContext();

	const artistId = user ? user.artistId || null : null;

	useEffect(() => {
		if (artistId) {
			setLoading(true);

			// @ts-ignore
			v2api("/front/getCurrentArtist", null, (err, res) => {
				console.log('getCurrentArtist', res);
				setLoading(false);
				setArtist(res || null);
			});
		}
	}, [artistId]);

	return { artist, loading };
};

export const useStableIdParam = (entityType: string) => {
	const { id } = useParams<{ id: string }>();
	const [response, setResponse] = useState(null);

	useEffect(() => {
		// @ts-ignore
		v2api("/front/exsubStableId", { id, entityType }, (err, res) => {
			if (res) {
				setResponse(res);
			}
		});
	}, []);

	return response;
};

export type TMyGalleriesResponse = {
	list: IGallery[];
	isLoading: boolean;
};

export const useOwnedGalleries = (
	userId: string | null | undefined,
	userType: TUserType | null | undefined,
): TMyGalleriesResponse => {
	const [loading, setLoading] = useState(false);
	const [list, setList] = useState([]);

	const refresh = () => {
		if (userId && userType) {
			switch (userType) {
				case "artist":
				case "curator": {
					// @ts-ignore
					v2api(
						"/front/exsubGalleriesOwned",
						{ ownerId: userId },
						(err, res) => {
							if (res) setList(res);
							setLoading(false);
						},
					);
					break;
				}

				case "collector":
				default: {
					setList([]);
					setLoading(false);
				}
			}
		}
	};

	useEffect(() => {
		refresh();
	}, [userId]);

	return { isLoading: loading, list, refresh };
};

export const useUserType = (
	user: Partial<IUser> | null | undefined,
): TUserType | null => {
	if (user) {
		if (user.galleryData) return "curator";
		if (user.artistId) return "artist";
		return "collector";
	}
	return null;
};

export type TCustomArtwork = IArtPiece & { artist: IArtist };

export type TArtistArtworksResponse = {
	list: TCustomArtwork[];
	isLoading: boolean;
	isEmpty: boolean;
	artist: IArtist | null;
};

const response: TArtistArtworksResponse = {
	list: [],
	isLoading: true,
	isEmpty: false,
	artist: null,
};

export const useArtistArtworks = (
	artist: IArtist | null | undefined,
): TArtistArtworksResponse => {
	const [data, setData] = useState({
		list: [],
		isEmpty: false,
		artist: artist || null,
	});

	const [loading, setLoading] = useState(false);

	const refresh = () => {
		if (artist && artist._id) {
			// @ts-ignore
			v2api(
				"/front/exsubUseArtistArtworks",
				{ artistId: artist._id },
				(err, res) => {
					setLoading(false);
					setData(
						res || {
							list: [],
							isEmpty: false,
							artist: artist || null,
						},
					);
				},
			);
		}
	};

	useEffect(() => {
		refresh();
	}, [artist]);

	return {
		isLoading: loading,
		refresh,
		...data,
	};
};

export const useUserListArtistsWithArtworks = (artistIds: any) => {
	const [list, setList] = useState([]);
	const [loading, setLoading] = useState(false);

	const refresh = useCallback(() => {
		// @ts-ignore
		v2api(
			"/front/exsubUseUserListArtistsWithArtworks",
			{ artistIds },
			(err, res) => {
				setLoading(false);
				setList(res || []);
			},
		);
	}, [artistIds]);

	useEffect(() => {
		refresh();
	}, [artistIds]);

	return { isLoading: loading, list, refresh };
};

export const useArtistsByOwnerWithArtworks = (
	ownerId: string | null | undefined,
) => {
	const [list, setList] = useState([]);
	const [loading, setLoading] = useState(false);

	const refresh = useCallback(
		(cb = null) => {
			// @ts-ignore
			v2api(
				"/front/exsubUseArtistsByOwnerWithArtworks",
				{ ownerId },
				(err, res) => {
					setLoading(false);
					setList(res || []);

					if (cb) cb(res);
				},
			);
		},
		[ownerId],
	);

	useEffect(() => {
		refresh();
	}, [ownerId]);

	return { isLoading: loading, list, refresh };
};

export type TOwnedArtworksResponse = {
	list: TCustomArtwork[];
	isLoading: boolean;
	isEmpty: boolean;
	artists: IArtist[];
};

export const useOwnedArtworks = (
	user: IUser | null | undefined,
	userType: TUserType | null | undefined,
	forceRefrsh: boolean | null | undefined,
): TOwnedArtworksResponse => {
	const defaultData = {
		list: [],
		isEmpty: true,
		artists: [],
	};

	const [data, setData] = useState(defaultData);
	const [loading, setLoading] = useState(false);

	const refresh = () => {
		if (userType && user) {
			// @ts-ignore
			v2api("/front/exsubUseOwnedArtworks", { userType, user }, (err, res) => {
				setLoading(false);
				setData(res || defaultData);
			});
		}
	};

	useEffect(() => {
		refresh();
	}, [userType, user, forceRefrsh]);

	return { refresh, isLoading: loading, ...data };
};

export const useGalleryDataById = (oid) => {
	const [owner, setOwner] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (oid) {
			// @ts-ignore
			v2api("/front/exsubUseGalleryDataById", { id: oid }, (err, res) => {
				setLoading(false);

				if (res) {
					setOwner(res);
				}
			});
		}
	}, [oid]);

	return { loading, owner };
};

export const useGalleryDataGalleryProfilePublic = (id) => {
	const [response, setResponse] = useState(null);

	const fetchGallery = useCallback(() => {
		if (id) {
			v2api("/users/getGalleryDataProfile", { id }, (err, res) => {
				if (err) {
					console.log("1error");
					setResponse({
						loadingGalleryData: () => false,
						user: null,
						stableId: null,
					});
				} else {
					setResponse({
						loadingGalleryData: () => false,
						user: res ? res.user : null,
						stableId: res ? res.stableId : null,
					});
				}
			});
		}
	}, []);

	useEffect(() => {
		fetchGallery();
	}, []);

	return !response
		? {
				loadingGalleryData: () => true,
				user: null,
				stableId: null,
			}
		: response;
};

export const useOwnedGalsGalleryProfilePublic = (ownerId) => {
	const [galleries, setGalleries] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (ownerId) {
			// @ts-ignore
			v2api(
				"/front/exsubUseOwnedGalsGalleryProfilePublic",
				{ ownerId },
				(err, res) => {
					setLoading(false);
					setGalleries(res || []);
				},
			);
		} else {
			setLoading(false);
		}
	}, [ownerId]);

	return {
		galsLoading: () => loading,
		galleries,
	};
};

export const useOrdersPurchases = () => {
	const [loading, setLoading] = useState(false);
	const defaultData = {
		orders: [],
		artists: [],
		arts: [],
	};

	const [data, setData] = useState(defaultData);

	useEffect(() => {
		//@ts-ignore
		v2api("/front/exsubUseOrdersPurchases", null, (err, res) => {
			setLoading(false);
			setData(res || defaultData);
		});
	}, []);

	return {
		ordersLoading: () => loading,
		...data,
	};
};

export const useUserSubsSubscriptionOptions = (userId, forceUpdate) => {
	const [subscriptions, setSubscriptions] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (userId) {
			// @ts-ignore
			v2api(
				"/front/exsubUseUserSubsSubscriptionOptions",
				{ userId },
				(err, res) => {
					setSubscriptions(res || []);
				},
			);
		}
	}, [userId, forceUpdate]);

	return { subscriptions, loadingSubs: () => loading };
};

export const useArtsByIdArtpieceEditModal = (id: string | null) => {
	const [data, setData] = useState({
		artPiece: null,
		artist: null,
	});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (id) {
			// @ts-ignore
			v2api(
				"/front/exsubUseArtsByIdArtpieceEditModal",
				{ artworkId: id },
				(err, res) => {
					setData(
						res || {
							artPiece: null,
							artist: null,
						},
					);

					setLoading(false);
				},
			);
		}
	}, [id]);

	return {
		isDataLoading: () => loading,
		...data,
	};
};

const defaultFetchState = {
	loading: true,
	data: null,
	error: null,
};

const defaultListFetchState = {
	data: [],
	error: null,
};

export function useGetAllDigitalTwins() {
	const [loading, setLoading] = useState(false);
	const [fetchState, setFetchState] = useState(defaultListFetchState);

	useEffect(() => {
		if (loading) {
			v2api("/exhibition/getAllDigitalTwins", null, (err, res) => {
				const commonRes = { loading: false };
				if (err) {
					const errorMessage = getErrorMessage(err);
					console.error("galleries.getAllDigitalTwins", errorMessage || err);
					setFetchState({ ...commonRes, error: errorMessage, data: [] });
				} else if (!res.data) {
					setFetchState({ ...commonRes, error: null, data: [] });
				}
				if (res.data) {
					setFetchState({ ...commonRes, error: null, data: res.data });
				} else {
					console.error("galleries.getAllDigitalTwins", "no-response");
					setFetchState({ ...defaultListFetchState, loading: false });
				}

				setLoading(false);
			});
		}
	}, [loading]);

	const refresh = useCallback(() => setLoading(true), []);

	return { ...fetchState, loading, refresh };
}

export function useGetPromoDigitalTwins() {
	const [loading, setLoading] = useState(false);
	const [fetchState, setFetchState] = useState(defaultListFetchState);

	useEffect(() => {
		if (loading) {
			v2api("/exhibition/promoDigitalTwins", null, (err, res) => {
				const commonRes = { loading: false };
				if (err) {
					const errorMessage = getErrorMessage(err);
					console.error("gallery.getDemoGalleries", errorMessage || err);
					setFetchState({ ...commonRes, error: errorMessage, data: [] });
				} else if (!res.data) {
					setFetchState({ ...commonRes, error: null, data: [] });
				}
				if (res.data) {
					setFetchState({ ...commonRes, error: null, data: res.data });
				} else {
					console.error("gallery.getDemoGalleries", "no-response");
					setFetchState({ ...defaultListFetchState, loading: false });
				}

				setLoading(false);
			});
		}
	}, [loading]);

	const refresh = useCallback(() => setLoading(true), []);

	return { ...fetchState, loading, refresh };
}

export function useGetDemoGalleries() {
	const [loading, setLoading] = useState(true);
	const [fetchState, setFetchState] = useState(defaultListFetchState);

	useEffect(() => {
		v2api("/exhibition/getDemoGalleries", null, (err, res) => {
			const commonRes = { loading: false };
			if (err) {
				const errorMessage = getErrorMessage(err);
				console.error("gallery.getDemoGalleries", errorMessage || err);
				setFetchState({ ...commonRes, error: errorMessage, data: [] });
			} else if (!res.data) {
				setFetchState({ ...commonRes, error: null, data: [] });
			}
			if (res.data) {
				setFetchState({ ...commonRes, error: null, data: res.data });
			} else {
				console.error("gallery.getDemoGalleries", "no-response");
				setFetchState({ ...defaultListFetchState, loading: false });
			}

			setLoading(false);
		});
	}, []);

	const refresh = useCallback(() => setLoading(true), []);

	return { ...fetchState, loading, refresh };
}

export function useGetGalleryViewDataById(id: string) {
	const [fetchState, setFetchState] = useState(defaultFetchState);

	useEffect(() => {
		v2api("/exhibition/getViewData", { id }, (err, res) => {
			const commonRes = { loading: false };
			if (err) {
				const errorMessage = getErrorMessage(err);
				console.error("gallery.getViewData", errorMessage || err);
				setFetchState({ ...commonRes, error: errorMessage, data: null });
			} else if (!res.data) {
				setFetchState({ ...commonRes, error: null, data: null });
			}
			if (res.data) {
				setFetchState({ ...commonRes, error: null, data: res.data });
			} else {
				console.error("gallery.getViewData", "no-response");
				setFetchState({ ...defaultFetchState, loading: false });
			}
		});
	}, [id]);

	return fetchState;
}

export function useGetGalleryById(id: string) {
	const [fetchState, setFetchState] = useState(defaultFetchState);

	useEffect(() => {
		v2api("/exhibition/getById", { id }, (err, res) => {
			const commonRes = { loading: false };
			if (err) {
				const errorMessage = getErrorMessage(err);
				console.error("gallery.getById", errorMessage || err);
				setFetchState({ ...commonRes, error: errorMessage, data: null });
			} else if (!res.data) {
				setFetchState({ ...commonRes, error: null, data: null });
			}
			if (res.data) {
				setFetchState({ ...commonRes, error: null, data: res.data });
			} else {
				console.error("gallery.getById", "no-response");
				setFetchState({ ...defaultFetchState, loading: false });
			}
		});
	}, [id]);

	return fetchState;
}

export const useGallery = (id: string) => {
	const [gallery, setGallery] = useState();

	useEffect(() => {
		// @ts-ignore
		v2api("/front/exsubUseGallery", { id }, (err, res) => {
			setGallery(res || undefined);
		});
	}, [id]);

	return gallery;
};

/**
 * art pieces by id for a given author
 */
export const useArtPiecesById = (aid: string | null | undefined) => {
	const [data, setData] = useState({ artist: null, artPieces: [] });
	const [loading, setLoading] = useState(false);

	const refresh = useCallback(
		(cb = null) => {
			setLoading(true);
			// @ts-ignore
			v2api("/front/exsubUseArtPiecesById", { artistId: aid }, (err, res) => {
				console.warn("front/exsubUseArtPiecesById", err, res);
				setLoading(false);

				if (res) {
					setData({
						artist: res.artist || null,
						artPieces: res.artworks || [],
					});
					if (typeof cb === "function") cb(res);
				}
			});
		},
		[aid],
	);

	useEffect(() => {
		refresh();
	}, [aid]);

	return { refresh, isLoading: loading, ...data };
};

export const useArtistByIdOrNickname = (id: string | null | undefined) => {
	const [data, setData] = useState({ owner: null, artist: null });
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		// @ts-ignore
		v2api("/front/exsubUseArtistByIdOrNickname", { id }, (err, res) => {
			setLoading(false);
			setData({
				owner: res.owner || null,
				artist: res.artist || null,
			});
		});
	}, [id]);

	return { isLoading: loading, artist: data.artist, owner: data.owner };
};

export const useArtPiecesByIdsWithAuthors = (ids: string[]) => {
	const [artPieces, setArtPieces] = useState([]);
	const [loading, setLoading] = useState(false);

	const id = ids[0];

	useEffect(() => {
		setLoading(true);

		console.log("ids1", id);
		if (Array.isArray(ids) && ids.length > 0) {
			// @ts-ignore
			v2api(
				"/front/exsubUseArtPiecesByIdsWithAuthors",
				{ ids: [id] },
				(err, res) => {
					setLoading(false);
					setArtPieces(res || []);
				},
			);
		} else {
			setLoading(false);
		}
	}, [id]);

	return {
		isLoading: loading,
		artPieces,
	};
};

export const useAllAvailablePaintings = (
	ownerId: string,
	isPublishedQuery: boolean,
	forceUpdate?: boolean,
) => {
	const [data, setData] = useState({
		paintings: [],
		artists: [],
	});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		//@ts-ignore
		v2api(
			"/front/exsubUseAllAvailablePaintings",
			{ ownerId, isPublishedQuery },
			(err, res) => {
				setData(
					res || {
						paintings: [],
						artists: [],
					},
				);

				setLoading(false);
			},
		);
	}, [ownerId, forceUpdate]);

	return { isLoading: loading, ...data };
};

export const useGalleriesByArtist = (
	search?: string,
	onSuccess?: (search: string) => void,
) => {
	const response = {
		exhibitions: [] as TGalleryWithOwner[],
		artists: [],
		galleries: [],
		count: 0,
		exhibitionsCount: 0,
		artistsCount: 0,
		galleriesCount: 0,
		loading: false,
	};

	return response;
};

export const useUserListData = (
	listId: string,
	params: { public?: boolean; unlisted?: boolean } = { public: false },
) => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);
	const [touched, setTouched] = useState(false);

	const refresh = useCallback(() => {
		setLoading(true);
		// @ts-ignore
		v2api("/front/exsubUseUserListData", { listId, params }, (err, res) => {
			setLoading(false);
			setData(res || null);
			setTouched(true);
		});
	}, [listId, params.public]);

	useEffect(() => {
		refresh();
	}, [listId, params.public]);
	useEffect(() => {
		refresh();
	}, []);

	return { touched, loading, data, refresh };
};

export const useUserListsByUserId = (
	userId: string,
	params: { public?: boolean; search?: string } = {},
) => {
	const [userLists, setUserLists] = useState([]);
	const [loading, setLoading] = useState(false);
	const [touched, setTouched] = useState(false);

	const refresh = useCallback(() => {
		v2api("/front/exsubUseUserListsByUserId2", { userId }, (err, res) => {
			setUserLists(res || []);
			setLoading(false);
			setTouched(true);
		});
	}, [userId]);

	useEffect(() => {
		// @ts-ignore
		refresh();
	}, [userId, params.public]);

	return { userLists, loading, touched, refresh };
};

export const useFreeTemplates = () => {
	const [loading, setLoading] = useState(false);
	const [list, setList] = useState([]);
	const [touched, setTouched] = useState(false);

	useEffect(() => {
		setLoading(true);
		// @ts-ignore
		v2api("/front/exsubUseFreeTemplates", null, (err, res) => {
			setTouched(true);
			setList(res || []);
			setLoading(false);
		});
	}, []);

	return { list, loading, touched };
};

export const useOwnerData = (ownerId) => {
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		// @ts-ignore
		v2api("/front/exsubUseOwnerData", { ownerId }, (err, res) => {
			setData(res || null);
			setLoading(false);
		});
	}, [ownerId]);

	return { loading, data };
};

export const useUserLists = (userId: string | null, search?: string) => {
	const [userLists, setUserLists] = useState([]);
	const [touched, setTouched] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (userId) {
			// @ts-ignore
			v2api("/front/exsubUseUserLists", { userId, search }, (err, res) => {
				setUserLists(res || []);
				setLoading(false);
				setTouched(true);
			});
		}
	}, [userId, search]);

	return { userLists, loading, touched };
};

export const useUserListsById = (id?: string | null) => {
	const { userLists, loading } = useUserLists(id);

	return {
		allArtworks: userLists.reduce(
			(all: string[], list) => all.concat(list.artworks),
			[],
		),
		isLoading: loading,
		userLists,
	};
};

export const useUserLikesById = (arts?: string[] | null) => {
	const [userLikes, setUserLikes] = useState([]);
	const { userId } = useCurrentUserContext();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (userId) {
			// @ts-ignore
			v2api("/front/exsubUseUserLikesById", { arts }, (err, res) => {
				setLoading(false);
				setUserLikes(res || []);
			});
		}
	}, [arts]);

	return { userLikes, isLoading: loading };
};

export const useUserListsWidthArtistsById = (id?: string | null) => {
	const [data, setData] = useState({
		userLists: [],
		allArtworks: [],
		artistsFromList: [],
	});

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		// @ts-ignore
		v2api("/front/exsubUseUserListsWidthArtistsById", { id }, (err, res) => {
			setData(
				res || {
					userLists: [],
					allArtworks: [],
					artistsFromList: [],
				},
			);

			setLoading(false);
		});
	}, [id]);

	return { ...data, isLoading: loading };
};

export const useGalleriesByArtistId = (id: string, onlyPublished: boolean) => {
	const [loading, setLoading] = useState(false);
	const [galleries, setGalleries] = useState([]);

	useEffect(() => {
		// @ts-ignore
		v2api(
			"/front/exsubUseGalleriesByArtistId",
			{ id, onlyPublished },
			(err, res) => {
				setGalleries(res || []);
				setLoading(false);
			},
		);
	}, [id]);

	return { loading, galleries };
};
