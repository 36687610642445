import React, { Suspense, useCallback, useMemo, useState } from "react";

import AddToListModal from "../views/Modals/AddToListModal";

export interface IAddToUserListContextValue {
	addToUserList: (artworksToAdd: string[]) => void;
}

export const AddToUserListContext =
	React.createContext<IAddToUserListContextValue>({
		addToUserList: () => null,
	});

export interface IModalState {
	isOpen: boolean;
	data: null | {
		artworks: string[];
	};
}

export const initialModalState: IModalState = {
	isOpen: false,
	data: null,
};

export interface IAddToUserListProviderProps {
	children?: React.ReactNode;
}

export const AddToUserListProvider: React.FC<IAddToUserListProviderProps> = ({
	children,
}) => {
	const [modalState, setModalState] = useState<IModalState>(initialModalState);

	const addToUserList = useCallback((artworks: string[]) => {
		setModalState({
			isOpen: true,
			data: { artworks },
		});
	}, []);

	const closeAddToUserListModal = useCallback(
		() => setModalState(initialModalState),
		[],
	);

	const value = useMemo(() => ({ addToUserList }), [addToUserList]);

	return (
		<AddToUserListContext.Provider value={value}>
			{children}

			{modalState.isOpen && (
				<Suspense fallback={null}>
					<AddToListModal
						isOpen={modalState.isOpen}
						onClose={closeAddToUserListModal}
						artworks={modalState.data.artworks}
					/>
				</Suspense>
			)}
		</AddToUserListContext.Provider>
	);
};
