import React, { type Ref, forwardRef } from "react";
import type { SVGProps } from "react";

export const SearchIcon = forwardRef(
	(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={32}
				height={32}
				fill="none"
				viewBox="0 0 32 32"
				className={`icon ${props.className || ""}`}
				ref={ref}
				{...props}
			>
				<path
					stroke="currentColor"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1.5}
					d="M16 28c6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12S4 9.372 4 16c0 6.627 5.373 12 12 12M26.924 25.925l2 2"
				/>
			</svg>
		);
	},
);
