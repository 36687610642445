import React from "react";

export { PHONE_CODES } from "./phone_codes";

export const LSKEY_LOGIN_EXPIRES = "Meteor.loginTokenExpires";

export const LSKEY_LOGIN_TOKEN = "Meteor.loginToken";

export const LSKEY_USER_ID = "Meteor.userId";

export const getEmailAddress = (user) =>
	user && Array.isArray(user.emails) && user.emails.length > 0
		? user.emails[0]?.address
		: "";
export const GALLERY_ACCESS_OPTIONS = [
	{ value: "free", label: "Free Access" },
	{ value: "password", label: "Access with password" },
] as const;

export const COUNTRIES = [
	"Afghanistan",
	"Albania",
	"Algeria",
	"American Samoa",
	"Andorra",
	"Angola",
	"Anguilla",
	"Antarctica",
	"Antigua and Barbuda",
	"Argentina",
	"Armenia",
	"Aruba",
	"Australia",
	"Austria",
	"Azerbaijan",
	"Bahamas",
	"Bahrain",
	"Bangladesh",
	"Barbados",
	"Belarus",
	"Belgium",
	"Belize",
	"Benin",
	"Bermuda",
	"Bhutan",
	"Bolivia",
	"Bosnia and Herzegovina",
	"Botswana",
	"Bouvet Island",
	"Brazil",
	"British Indian Ocean Territory",
	"Brunei",
	"Bulgaria",
	"Burkina Faso",
	"Burundi",
	"Cambodia",
	"Cameroon",
	"Canada",
	"Cape Verde",
	"Cayman Islands",
	"Central African Republic",
	"Chad",
	"Chile",
	"China",
	"Christmas Island",
	"Cocos (Keeling) Islands",
	"Colombia",
	"Comoros",
	"Congo",
	"The Democratic Republic of Congo",
	"Cook Islands",
	"Costa Rica",
	"Ivory Coast",
	"Croatia",
	"Cuba",
	"Cyprus",
	"Czech Republic",
	"Denmark",
	"Djibouti",
	"Dominica",
	"Dominican Republic",
	"East Timor",
	"Ecuador",
	"Egypt",
	"England",
	"El Salvador",
	"Equatorial Guinea",
	"Eritrea",
	"Estonia",
	"Ethiopia",
	"Falkland Islands",
	"Faroe Islands",
	"Fiji Islands",
	"Finland",
	"France",
	"French Guiana",
	"French Polynesia",
	"French Southern territories",
	"Gabon",
	"Gambia",
	"Georgia",
	"Germany",
	"Ghana",
	"Gibraltar",
	"Greece",
	"Greenland",
	"Grenada",
	"Guadeloupe",
	"Guam",
	"Guatemala",
	"Guernsey",
	"Guinea",
	"Guinea-Bissau",
	"Guyana",
	"Haiti",
	"Heard Island and McDonald Islands",
	"Holy See (Vatican City State)",
	"Honduras",
	"Hong Kong",
	"Hungary",
	"Iceland",
	"India",
	"Indonesia",
	"Iran",
	"Iraq",
	"Ireland",
	"Israel",
	"Isle of Man",
	"Italy",
	"Jamaica",
	"Japan",
	"Jersey",
	"Jordan",
	"Kazakhstan",
	"Kenya",
	"Kiribati",
	"Kuwait",
	"Kyrgyzstan",
	"Laos",
	"Latvia",
	"Lebanon",
	"Lesotho",
	"Liberia",
	"Libyan Arab Jamahiriya",
	"Liechtenstein",
	"Lithuania",
	"Luxembourg",
	"Macao",
	"North Macedonia",
	"Madagascar",
	"Malawi",
	"Malaysia",
	"Maldives",
	"Mali",
	"Malta",
	"Marshall Islands",
	"Martinique",
	"Mauritania",
	"Mauritius",
	"Mayotte",
	"Mexico",
	"Micronesia, Federated States of",
	"Moldova",
	"Monaco",
	"Mongolia",
	"Montserrat",
	"Montenegro",
	"Morocco",
	"Mozambique",
	"Myanmar",
	"Namibia",
	"Nauru",
	"Nepal",
	"Netherlands",
	"Netherlands Antilles",
	"New Caledonia",
	"New Zealand",
	"Nicaragua",
	"Niger",
	"Nigeria",
	"Niue",
	"Norfolk Island",
	"North Korea",
	"Northern Ireland",
	"Northern Mariana Islands",
	"Norway",
	"Oman",
	"Pakistan",
	"Palau",
	"Palestine",
	"Panama",
	"Papua New Guinea",
	"Paraguay",
	"Peru",
	"Philippines",
	"Pitcairn",
	"Poland",
	"Portugal",
	"Puerto Rico",
	"Qatar",
	"Reunion",
	"Romania",
	"Russian Federation",
	"Rwanda",
	"Saint Helena",
	"Saint Kitts and Nevis",
	"Saint Lucia",
	"Saint Pierre and Miquelon",
	"Saint Vincent and the Grenadines",
	"Samoa",
	"San Marino",
	"Sao Tome and Principe",
	"Saudi Arabia",
	"Scotland",
	"Senegal",
	"Serbia",
	"Seychelles",
	"Sierra Leone",
	"Singapore",
	"Slovakia",
	"Slovenia",
	"Solomon Islands",
	"Somalia",
	"South Africa",
	"South Georgia and the South Sandwich Islands",
	"South Korea",
	"South Sudan",
	"Spain",
	"Sri Lanka",
	"Sudan",
	"Suriname",
	"Svalbard and Jan Mayen",
	"Swaziland",
	"Sweden",
	"Switzerland",
	"Syria",
	"Tajikistan",
	"Tanzania",
	"Thailand",
	"Timor-Leste",
	"Togo",
	"Tokelau",
	"Tonga",
	"Trinidad and Tobago",
	"Tunisia",
	"Turkey",
	"Turkmenistan",
	"Turks and Caicos Islands",
	"Tuvalu",
	"Uganda",
	"Ukraine",
	"United Arab Emirates",
	"United Kingdom",
	"United States",
	"United States Minor Outlying Islands",
	"Uruguay",
	"Uzbekistan",
	"Vanuatu",
	"Venezuela",
	"Vietnam",
	"Virgin Islands, British",
	"Virgin Islands, U.S.",
	"Wales",
	"Wallis and Futuna",
	"Western Sahara",
	"Yemen",
	"Zambia",
	"Zimbabwe",
];

export type TUserType = "artist" | "curator" | "collector";

export const GALLERY_TYPES = [
	{ name: "Museum", id: "museum" },
	{ name: "Tree Gallery", id: "treegallery" },
	{ name: "Big Gallery", id: "biggallery" },
];

export const SORT_OPTIONS = [
	{ name: "Pricing - High", id: "pricingHigh" },
	{ name: "Pricing - Low", id: "pricingLow" },
	{ name: "Recently Listed", id: "recent" },
	{ name: "Popular", id: "popular" },
];
export const SORT_OPTIONS_GALLERY = [
	{ name: "Recently Listed", id: "recent" },
	{ name: "Popular", id: "popular" },
];
export const SORT_OPTIONS_ARTIST = [
	{ name: "Recently Listed", id: "recent" },
	{ name: "Popular", id: "popular" },
];

export const ART_METHODS = [
	"Acrylic paint",
	"Acrylic resin",
	"Adhesive",
	"Adobe",
	"Agate",
	"AI",
	"Alabaster",
	"Aluminium",
	"Amber",
	"Amethyst",
	"Andesite",
	"Azurite",
	"Bakelite",
	"Bark",
	"Basalt",
	"Beech",
	"Beeswax",
	"Birch",
	"Biscuit porcelain",
	"Bone",
	"Book",
	"Brass",
	"Brick",
	"Brocade",
	"Bronze",
	"Calotype",
	"Canvas",
	"Cardboard",
	"Carrara marble",
	"Cartridge paper",
	"Cast iron",
	"Celluloid",
	"Ceramic",
	"Chalk",
	"Charcoal",
	"Cherry",
	"Chiffon",
	"Chintz",
	"Chromium",
	"Cinnabar",
	"Clay",
	"Coal",
	"Cobalt",
	"Cochineal",
	"Colored pencil",
	"Concrete",
	"Conté",
	"Copper",
	"Coral",
	"Cord",
	"Cork",
	"Cotton",
	"Crayon",
	"Crystal",
	"Damask",
	"Diamond",
	"Digital",
	"Diorite",
	"Distemper",
	"Drawing",
	"Dye",
	"Ebony",
	"Egg",
	"Electrical cable",
	"Emerald",
	"Enamel paint",
	"Encaustic painting",
	"Engraving",
	"Epoxy",
	"Etching",
	"Eucalyptus",
	"Faience",
	"Feather",
	"Feldspar",
	"Felt",
	"Fiberglass",
	"Fir",
	"Flint",
	"Foil",
	"Formica",
	"Garnet",
	"Gauze",
	"Gemstone",
	"Gesso",
	"Glass",
	"Gold",
	"Gold leaf",
	"Gouache",
	"Granite",
	"Graph paper",
	"Graphite",
	"Gum arabic",
	"Hair",
	"Hematite",
	"Hessian fabric",
	"Hide",
	"Horsehair",
	"India ink",
	"Industrial Hemp",
	"Ink",
	"Iron",
	"Ivory",
	"Jade",
	"Jadeite",
	"Jasper",
	"Jet",
	"Jute",
	"Lace",
	"Lacquer",
	"Laid paper",
	"Lapis lazuli",
	"Latex",
	"Lead",
	"Lead glass",
	"Leaf",
	"Leather",
	"Limestone",
	"Linen",
	"Lustreware",
	"Mahogany",
	"Malachite",
	"Manganese",
	"Maple",
	"Marble",
	"Masonite",
	"Mercury",
	"Metal",
	"Metallic paint",
	"Milk glass",
	"Mohair",
	"Murano glass",
	"Muslin",
	"Nacre",
	"Natural rubber",
	"Neon",
	"Nephrite",
	"Newsprint",
	"Nickel",
	"Nylon",
	"Oak",
	"Obsidian",
	"Oil on paper",
	"Oil paint",
	"Oil pastel",
	"Olive",
	"Onyx",
	"Paper",
	"Paper negative",
	"Papyrus",
	"Parian marble",
	"Pavonazzo marble",
	"Pearl",
	"Pebble",
	"Pen",
	"Pencil",
	"Peridot",
	"Pewter",
	"Photograph",
	"Photographic film",
	"Photographic paper",
	"Pigment",
	"Pine",
	"Pitch",
	"Plaster",
	"Plastic",
	"Platinum",
	"Plywood",
	"Polycarbonate",
	"Polyester",
	"Polypropylene",
	"Polystyrene",
	"Polyurethane",
	"Porcelain",
	"Pottery",
	"Quartz",
	"Quartzite",
	"Rattan",
	"Rayon",
	"Resin",
	"Rhinestone",
	"Ribbon",
	"Rice paper",
	"Rock",
	"Root",
	"Rope",
	"Sand",
	"Sandstone",
	"Sanguine",
	"Sapphire",
	"Satin",
	"Sawdust",
	"Sculpture",
	"Seashell",
	"Seed",
	"Sheet metal",
	"Silicon",
	"Silk",
	"Silver",
	"Silver bromide",
	"Silver halide",
	"Skin",
	"Slate",
	"Soapstone",
	"Spray painting",
	"Spruce",
	"Stained glass",
	"Stainless steel",
	"Steel",
	"Sterling silver",
	"Stoneware",
	"Stucco",
	"Taffeta",
	"Teak",
	"Tempera",
	"Terracotta",
	"Textile",
	"Tin",
	"Tissue paper",
	"Titanium",
	"Tooth",
	"Topaz",
	"Tortoiseshell",
	"Tracing paper",
	"Travertine",
	"Tulipwood",
	"Turquoise",
	"Tusk",
	"Twill",
	"Varnish",
	"Vellum",
	"Velvet",
	"Vitreous enamel",
	"Walnut",
	"Watercolor painting",
	"Wax",
	"Willow",
	"Wire",
	"Wood",
	"Wool",
	"Wrought iron",
	"Yarn",
	"Zinc",
];

export const ART_METHOD_OPTIONS =
	ART_METHODS.map((am) => ({
		value: am,
		label: am,
	})) || [];

export const ART_FORM_OPTIONS = [
	{ label: "Video", value: "video" },
	{ label: "Painting", value: "painting" },
	{ label: "Digital", value: "digital" },
];
export const ART_STATUS_OPTIONS = [
	{ label: "For Sale", value: true },
	{ label: "Not for sale", value: false },
];

export const ART_STYLES = [
	"Abstract expressionism",
	"Academic art",
	"Aestheticism",
	"American Impressionism",
	"American modernism",
	"American Realism",
	"American Renaissance",
	"Amsterdam Impressionism",
	"Antwerp school",
	"Art Deco",
	"Art Nouveau",
	"Arte Povera",
	"Arts and Crafts movement",
	"Ashcan School",
	"Barbizon school",
	"Baroque",
	"Bauhaus style",
	"Biomorphism",
	"Bolognese School",
	"Bronze Age",
	"Caravaggisti",
	"Classicism",
	"Cleveland School",
	"Color field",
	"Conceptual art",
	"Constructivism",
	"Contemporary art",
	"Cubism",
	"Dada",
	"De Stijl",
	"Der Blaue Reiter",
	"Die Brücke",
	"Dutch and Flemish Renaissance painting",
	"Dutch Golden Age",
	"Early Christian art and architecture",
	"Early renaissance",
	"Expressionism",
	"Fauvism",
	"Florentine painting",
	"Fluxus",
	"Folk art",
	"French Renaissance",
	"Futurism",
	"Geometric abstraction",
	"German Expressionism",
	"German Renaissance",
	"Good Design",
	"Gothic art",
	"Hague School",
	"Harlem Renaissance",
	"Heidelberg School",
	"High Renaissance",
	"Hudson River School",
	"Hyperrealism",
	"Impressionism",
	"Italian Renaissance",
	"Japonisme",
	"Kinetic art",
	"Land art",
	"Les Nabis",
	"Luminism",
	"Magical Realism",
	"Mannerism",
	"Metaphysical art",
	"Milanese School",
	"Minimalism",
	"Mission School",
	"Modern art",
	"Modernism",
	"Naïve art",
	"Naturalism",
	"Nazarene movement",
	"Neo-expressionism",
	"Neo-Impressionism",
	"Neo-romanticism",
	"Neoclassicism",
	"New Objectivity",
	"Nihonga",
	"Northern Renaissance",
	"Norwich School",
	"Nouveau réalisme",
	"Op art",
	"Outsider art",
	"Paduan School",
	"Pennsylvania Impressionism",
	"Photorealism",
	"Pop art",
	"Post-Impressionism",
	"Postminimalism",
	"Postmodernism",
	"Pre-Raphaelite Brotherhood",
	"Primitivism",
	"Public art",
	"Rayonism",
	"Realism",
	"Regionalism",
	"Renaissance",
	"Rococo",
	"Romanesque art",
	"Romanticism",
	"School of Ferrara",
	"School of Fontainebleau",
	"School of Paris",
	"Section d'Or",
	"Shin-hanga",
	"Sienese School",
	"Social realism",
	"Socialist realism",
	"Sōsaku-hanga",
	"Spanish Eclecticism",
	"Spanish Renaissance",
	"Street art",
	"Sturm und Drang",
	"Suprematism",
	"Surrealism",
	"Symbolism",
	"Synthetism",
	"Tachisme",
	"Tonalism",
	"Ukiyo-e",
	"Utrecht Caravaggism",
	"Venetian painting",
	"Washington Color School",
	"Young British Artists",
];

export const ART_STYLE_OPTIONS =
	ART_STYLES.map((as) => ({
		value: as,
		label: as,
	})) || [];
export const FALLBACK_GALLERY_PREVIEW_URL =
	"/images/galleries/01gallery-preview.png";

export const NO_IMAGE = {
	artist: "/images/placeholders/artist_new.png",
	artpiece: "/images/placeholders/artwork_new.jpg",
	gallery: "",
};

export const PLACEHOLDER = {
	artist: "/images/placeholders/artist_new.png",
	artpiece: "/images/placeholders/artwork_new.jpg",
	gallery: "",
};

export const MAX_RES = "maxRes";

const templatesLink = (text: string) => (
	<a
		className="plan-feature-link"
		href="/all_templates.pdf"
		target="_blank"
		rel="noreferrer"
	>
		{text}
	</a>
);

const freeTemplatesLink = (text: string) => (
	<a
		className="plan-feature-link"
		href="/free_templates.pdf"
		target="_blank"
		rel="noreferrer"
	>
		{text}
	</a>
);

export const FREE_CURATOR_FEATURES = [
	"1 Exhibition",
	<>2 Exhibition {freeTemplatesLink("Templates")}</>,
	"10 Artwork or video uploads",
	"1 Artist",
];

export const ADVANCED_CURATOR_FEATURES = [
	"5 Exhibitions at the same time",
	<>Unlimited {templatesLink("Templates")}</>,
	"150 Artwork or video uploads",
	"5 Artists",
	"Guaranteed Marketplace Visibility",
];

export const PREMIUM_CURATOR_FEATURES = [
	"10 Exhibitions at the same time",
	<>Unlimited {templatesLink("Templates")}</>,
	"Unlimited Artwork or video uploads",
	"15 Artists",
	"Guaranteed Marketplace Visibility",
	"VR Exhibitions",
];

export const FREE_ARTIST_FEATURES = [
	"1 Exhibition",
	<>2 Exhibition {freeTemplatesLink("Templates")}</>,
	"10 Artwork or video uploads",
];
export const ADVANCED_ARTIST_FEATURES = [
	"2 Exhibitions",
	<>Unlimited Exhibition {templatesLink("Templates")}</>,
	"50 Artwork or video uploads",
	"Guaranteed Marketplace Visibility",
];

export const PREMIUM_ARTIST_FEATURES = [
	"3 Exhibitions at the same time",
	<>Unlimited Exhibition {templatesLink("Templates")}</>,
	"Unlimited Artwork or video uploads",
	"Guaranteed Marketplace Visibility",
	"VR Exhibitions",
];

export const FREE_MEMBER_FEATURES = [
	"Create and manage 3D galleries for artists and clients: 1 gallery",
	"Manage, display and sell your art: 10 pieces",
	"Create user profiles for artists or collectors: 1 profile",
	"Sharing for all socials: Instagram, Facebook, Twitter, WhatsApp",
	"Meet clients in your gallery, with voice chat (Avatars)",
];

export const USER_TYPE_GALLERY = "curator";
export const USER_TYPE_ARTIST = "artist";
export const USER_TYPE_USER = "user";

export const CANCELLATION_REASONS = [
	"Using a different platform",
	"Lost interest in the platform",
	"Not satisfied with the price",
	"Not satisfied with the functionality",
	"Low interest in my profile",
	"Not satisfied with the quality of the platform",
	"There are no updates and innovations",
	"Other reasons",
];
