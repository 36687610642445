import React, { useMemo } from "react";
import { ArtworkIcon } from "../components/svg/icons/Artwork";
import { CirclesFourIcon } from "../components/svg/icons/CirclesFour";
import { CreditCardIcon } from "../components/svg/icons/CreditCard";
import { CubeIcon } from "../components/svg/icons/Cube";
import { DiamondIcon } from "../components/svg/icons/Diamond";
import { GalleryIcon } from "../components/svg/icons/Gallery";
import { PaintBrushBroadIcon } from "../components/svg/icons/PaintBrushBroad";
import { UserSquare2Icon } from "../components/svg/icons/UserSquare2";
import { checkIsUserAdmin } from "../utils/user";

export type TUserMenuItem = {
	id: string;
	order: number;
	isShown: boolean;
	helpLink?: string;
	to: string;
	title: string;
	icon: React.ReactNode;
};

export const useUserMenu = (
	user: IUser | null | undefined,
): TUserMenuItem[] => {
	return useMemo(() => {
		const isAdmin = user && checkIsUserAdmin(user);
		const isArtist = !!user?.artistId;
		const isGallery = !!user?.galleryData;
		const isArtLover = !isArtist && !isGallery;
		const isCreatedArtwork = user?.achievements?.firstArtwork?.value;
		const pronoun = isGallery ? "Our" : "My";

		return [
			{
				order: 1,
				id: "nl_user_profile_page",
				isShown: true,
				to: "/profile/user",
				title: "My Profile",
				icon: <UserSquare2Icon width={20} height={20} />,
			},
			{
				order: 2,
				id: "nl_gallery_profile_page",
				isShown: isGallery,
				to: "/profile/gallery",
				title: "Gallery Profile",
				icon: <DiamondIcon width={20} height={20} />,
				helpLink: "https://www.youtube.com/watch?v=pUzkcsXVOsg&t=7s",
			},
			{
				order: 3,
				id: "nl_artist_profile_page",
				isShown: isArtist,
				to: "/profile/artist",
				title: "Artist Profile",
				icon: <PaintBrushBroadIcon width={20} height={20} />,
				helpLink: "https://www.youtube.com/watch?v=R-G9QVNJKmg",
			},
			{
				order: 4,
				id: "nl_profile_artists_page",
				isShown: isGallery,
				to: "/profile/artists",
				title: `${pronoun} Artists`,
				icon: <PaintBrushBroadIcon width={20} height={20} />,
				helpLink: "https://www.youtube.com/watch?v=hmFTOTke-yA",
			},
			{
				order: 5,
				id: "nl_profile_artworks_page",
				isShown: !isArtLover,
				to: "/profile/artworks",
				title: `${pronoun} Artworks`,
				icon: <ArtworkIcon width={20} height={20} />,
				helpLink: "https://www.youtube.com/watch?v=ZZnFhW-VYYQ",
			},
			{
				order: 6,
				id: "nl_profile_exhibitions_page",
				isShown: !isArtLover,
				to: "/profile/exhibitions",
				title: `${pronoun} Exhibitions`,
				icon: <GalleryIcon width={20} height={20} />,
				helpLink: "https://www.youtube.com/watch?v=3oRhhNlMCJ8&t=4s",
			},
			{
				order: 7,
				isShown: true,
				id: "nl_profile_collections_page",
				to: "/profile/collections",
				title: `My Collections`,
				icon: <CirclesFourIcon width={20} height={20} />,
			},
			// {
			//   order: 8,
			//   id: 'nl_profile_analytics_page',
			//   isShown: isArtist && isCreatedArtwork,
			//   to: '/profile/analytics',
			//   title: 'Analytics',
			//   icon: <ChartPieSliceIcon width={20} height={20} />,
			// },
			{
				order: 9,
				id: "nl_profile_payout_page",
				isShown: !isArtLover && isCreatedArtwork,
				to: "/profile/payout-account",
				title: "Payout Account",
				icon: <CreditCardIcon width={20} height={20} />,
			},
			{
				order: 10,
				id: "nl_profile_purchases_page",
				isShown: isArtLover,
				to: "/profile/purchases",
				title: "My Purchases",
				icon: <CubeIcon width={20} height={20} />,
			},
			{
				order: 11,
				id: "nl_profile_orders_page",
				isShown: !isArtLover && isCreatedArtwork,
				to: "/profile/orders",
				title: "All Orders",
				icon: <CubeIcon width={20} height={20} />,
			},
		].filter((i) => i.isShown);
	}, [user]);
};
