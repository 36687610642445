import React, { type Ref, forwardRef } from "react";
import type { SVGProps } from "react";

export const EyeCrossedIcon = forwardRef(
	(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={32}
				height={32}
				fill="none"
				viewBox="0 0 32 32"
				className={`icon ${props.className || ""}`}
				ref={ref}
				{...props}
			>
				<path
					stroke="currentColor"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="m19.373 12.627-6.746 6.746a4.745 4.745 0 0 1-1.4-3.373A4.768 4.768 0 0 1 16 11.227c1.32 0 2.507.533 3.373 1.4"
				/>
				<path
					stroke="currentColor"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M23.76 7.693c-2.334-1.76-5-2.72-7.76-2.72-4.707 0-9.094 2.773-12.147 7.574-1.2 1.88-1.2 5.04 0 6.92 1.053 1.653 2.28 3.08 3.613 4.226M11.227 26.04c1.52.64 3.133.987 4.773.987 4.707 0 9.093-2.773 12.147-7.573 1.2-1.88 1.2-5.04 0-6.92a21.63 21.63 0 0 0-1.414-1.96"
				/>
				<path
					stroke="currentColor"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M20.68 16.934c-.347 1.88-1.88 3.413-3.76 3.76M12.626 19.373l-9.96 9.96M29.333 2.667l-9.96 9.96"
				/>
			</svg>
		);
	},
);
