import React, { type Ref, forwardRef } from "react";
import type { SVGProps } from "react";

export const Linkedin2Icon = forwardRef(
	(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={32}
				height={32}
				fill="none"
				viewBox="0 0 32 32"
				className={`icon ${props.className || ""}`}
				ref={ref}
				{...props}
			>
				<path
					fill="currentColor"
					d="M26.286 4H5.709C4.766 4 4 4.777 4 5.73v20.54c0 .953.766 1.73 1.709 1.73h20.577c.943 0 1.714-.777 1.714-1.73V5.73C28 4.777 27.229 4 26.286 4M11.254 24.571H7.696V13.118h3.563V24.57zM9.475 11.554a2.063 2.063 0 0 1 0-4.125c1.136 0 2.063.926 2.063 2.062a2.06 2.06 0 0 1-2.063 2.063M24.587 24.57H21.03V19c0-1.329-.026-3.037-1.848-3.037-1.853 0-2.137 1.446-2.137 2.94v5.668h-3.558V13.118H16.9v1.564h.048c.477-.9 1.64-1.848 3.37-1.848 3.6 0 4.27 2.373 4.27 5.459z"
				/>
			</svg>
		);
	},
);
