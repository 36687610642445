import { StandardPlans } from "../const/plans";

export const useUserPlan = (user) => {
	const current = StandardPlans.find(
		(p) => p.name === user?.subscriptionPlanId,
	);

	const isExceeded = (subject: "artists" | "artworks" | "galleries") => {
		switch (subject) {
			case "galleries": {
				return current &&
					current.maxNumOfGalleries !== -1 &&
					user &&
					user.currentDataForSub &&
					typeof user.currentDataForSub.totalGalleries === "number"
					? user.currentDataForSub.totalGalleries >= current.maxNumOfGalleries
					: false;
			}

			case "artists": {
				return current &&
					current.maxNumOfArtists !== -1 &&
					user &&
					user.currentDataForSub &&
					typeof user.currentDataForSub.totalArtists === "number"
					? user.currentDataForSub.totalArtists >= current.maxNumOfArtists
					: false;
			}

			case "artworks": {
				return current &&
					current.maxNumOfArtPieces !== -1 &&
					user &&
					user.currentDataForSub &&
					typeof user.currentDataForSub.totalArtPieces === "number"
					? user.currentDataForSub.totalArtPieces >= current.maxNumOfArtPieces
					: false;
			}

			default: {
				throw new Error("Unknown subject for sub");
			}
		}
	};
	return {
		current: current || null,
		isExceeded,
	};
};
