import React, { type ElementType, useEffect, useMemo, useState } from "react";
import Dropdown from "react-bootstrap/esm/Dropdown";
import type { DropdownToggleProps } from "react-bootstrap/esm/DropdownToggle";
import Form from "react-bootstrap/esm/Form";
import { Exclaim } from "../Icons/Exclaim";

interface SelectProps {
	name?: string;
	label?: string;
	error?: string;
	distinct?: boolean; // removes not selected option
	value: string;
	options: string[];
	onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
	noLabel?: boolean;
	className?: string;
	placeholder?: string;
	disabled?: boolean;
}

const NOT_SELECTED = "Not Selected";

const Select = ({
	options: defaultOptions,
	value,
	onChange,
	distinct,
	placeholder,
	noLabel,
	disabled,
	...inputProps
}: SelectProps) => {
	const _defaultOptions = distinct
		? defaultOptions
		: [NOT_SELECTED, ...defaultOptions];
	/*
    Select showing "Not selected" value by default,
    but if placeholder exists value must be undefined in order to show placeholder
  */
	const emptyValue = placeholder ? "" : NOT_SELECTED;

	if (distinct && !value) {
		throw new Error("Distinct select cannot have empty value");
	}

	const [displayValue, setDisplayValue] = useState(
		distinct ? value : value || emptyValue,
	);
	const [isOpen, setIsOpen] = useState(false);
	const [isFocused, setIsFocused] = useState(false);
	const [options, setOptions] = useState(_defaultOptions);

	useEffect(() => {
		setDisplayValue(distinct ? value : value || emptyValue);
		setOptions(_defaultOptions);
	}, [value]);

	const onToggle = (isOpen: boolean) => {
		setIsOpen(isFocused || isOpen);
		// !isOpen && setOptions(defaultOptions);
	};

	const onSelect = (eventKey: string | null) => {
		if (distinct) {
			// @ts-ignore
			onChange &&
				onChange({ target: { value: eventKey as unknown as string } });
			return;
		}
		const isEmpty = (v: string | null) =>
			v === NOT_SELECTED || v === "" || v === null;
		// @ts-ignore
		onChange &&
			onChange({ target: { value: isEmpty(eventKey) ? "" : eventKey } });
	};

	const handleSearch = (query: string) => {
		const searchResults = defaultOptions.filter((o) =>
			o.toLowerCase().includes(query.toLowerCase()),
		);

		setDisplayValue(query);
		setOptions(distinct ? searchResults : [NOT_SELECTED, ...searchResults]);
	};

	// @TODO refactor
	const toggleElem = useMemo(
		() =>
			((props) => (
				<CustomToggle disabled={disabled} noLabel={noLabel} {...props} />
			)) as ElementType,
		[noLabel, disabled],
	);

	const handleFocus = () => setIsFocused(true);
	const handleBlur = (e) => {
		setIsFocused(false);
		/*
      handleBlur will be fired after handleSelect. We need to determine
      if blur event was caused by click outside of select or by selecting an item in select.
    */
		const isCausedBySelectEvent =
			e.relatedTarget && e.relatedTarget.className.includes("dropdown-item");

		// Skips displayValue to already selected value or emptyValue.
		if (!isCausedBySelectEvent) {
			setDisplayValue(value || emptyValue);
			setOptions(_defaultOptions);
		}
	};

	return (
		<Dropdown
			className="dropdown-no-arrow select-dropdown"
			onToggle={onToggle}
			onSelect={onSelect}
			show={isOpen}
		>
			<Dropdown.Toggle
				as={toggleElem}
				value={displayValue}
				onChangeText={handleSearch}
				onFocus={handleFocus}
				onBlur={handleBlur}
				placeholder={placeholder}
				{...inputProps}
			/>

			<Dropdown.Menu
				style={{
					maxHeight: 300,
					overflowY: "auto",
					overflowX: "hidden",
					borderRadius: "20px",
					marginTop: "10px",
					boxShadow: "0px 30px 100px rgba(0, 0, 0, 0.2)",
				}}
				className="w-100 scrollbar p-s10"
			>
				{options.map((option, idx) => {
					const isLast = idx === options.length - 1;
					const margin = !isLast ? "mb-s10" : "";
					const selected =
						option === value ? "select-dropdown-selected-item" : "";

					return (
						<Dropdown.Item
							key={option}
							eventKey={option}
							className={`${margin} fs-fs14 ${selected}`}
						>
							{option}
						</Dropdown.Item>
					);
				})}
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default Select;

interface CustomToggleProps extends DropdownToggleProps {
	name?: string;
	label?: string;
	error?: string;
	value: string;
	disabled?: boolean;
	className?: string;
	placeholder?: string;
	noLabel?: boolean;
	onBlur(): void;
	onFocus(): void;
	onChangeText(value: string): void;
}

const CustomToggle: React.FC<CustomToggleProps> = React.forwardRef(
	(
		{
			onChangeText,
			placeholder,
			className,
			onClick,
			onFocus,
			onBlur,
			noLabel,
			disabled,
			label,
			value,
			error,
			name,
		},
		ref: React.ForwardedRef<HTMLDivElement>,
	) => (
		<Form.Group
			ref={ref}
			className={className}
			onClick={!disabled ? onClick : undefined}
		>
			{!noLabel && (
				<Form.Label
					className="fs-fs14 fw-medium mb-s10"
					style={{ lineHeight: "160%" }}
					htmlFor={name}
				>
					{label}
				</Form.Label>
			)}
			<Form.Control
				disabled={disabled}
				id={name}
				name={name}
				type="text"
				value={value}
				onChange={(e) => onChangeText(e.target.value)}
				isInvalid={!!error}
				placeholder={placeholder}
				autoComplete="off"
				onFocus={onFocus}
				onBlur={onBlur}
			/>
			{error && (
				<Form.Control.Feedback
					type="invalid"
					style={{ display: "flex", alignItems: "center" }}
				>
					<Exclaim style={{ marginRight: "8px" }} />
					{error}
				</Form.Control.Feedback>
			)}
		</Form.Group>
	),
);
