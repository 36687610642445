import React, { type Ref, forwardRef } from "react";
import type { SVGProps } from "react";

export const FacebookIcon = forwardRef(
	(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={32}
				height={32}
				fill="none"
				viewBox="0 0 32 32"
				className={`icon ${props.className || ""}`}
				ref={ref}
				{...props}
			>
				<path
					stroke="currentColor"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M9 13.333v5.334h4V28h5.333v-9.333h4l1.334-5.334h-5.334v-2.666a1.333 1.333 0 0 1 1.334-1.334h4V4h-4A6.667 6.667 0 0 0 13 10.667v2.666z"
				/>
			</svg>
		);
	},
);
