import React, { type Ref, forwardRef } from "react";
import type { SVGProps } from "react";

export const ArtworkIcon = forwardRef(
	(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={32}
				height={32}
				fill="none"
				viewBox="0 0 32 32"
				className={`icon ${props.className || ""}`}
				ref={ref}
				{...props}
			>
				<path
					stroke="currentColor"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1.5}
					d="M12.4 28h7.2c6 0 8.4-2.4 8.4-8.4v-7.2c0-6-2.4-8.4-8.4-8.4h-7.2C6.4 4 4 6.4 4 12.4v7.2c0 6 2.4 8.4 8.4 8.4"
				/>
				<path
					stroke="currentColor"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1.5}
					d="M12 13.333A2.667 2.667 0 1 0 12 8a2.667 2.667 0 0 0 0 5.333M5 25l5.866-4.285c.94-.686 2.296-.608 3.141.181l.393.376c.928.867 2.427.867 3.355 0l4.95-4.621c.928-.868 2.427-.868 3.356 0L28 18.463"
				/>
			</svg>
		);
	},
);
