import { useCallback, useEffect, useMemo, useState } from "react";

export const AVATAR_PLACEHOLDER_SRC = "/images/placeholders/artist_new.png";

export type WithPicture = {
	picture?: TPicture;
};
const cacheBust = (str: string, data: any) => {
	if (str.includes("?updatedAt=")) return str;

	if (!data.updatedAt || !(data.updatedAt instanceof Date)) {
		console.warn(
			"Failed migration. Some artist or gallery or user have invalid updatedAt field. Its either empty or not a valid date.",
		);
		return str;
	}

	return `${str}?t=${data.updatedAt.toISOString()}`;
};

const getAvatarData = (data: WithPicture | null | undefined) => {
	if (!data || !data.picture)
		return {
			url: AVATAR_PLACEHOLDER_SRC,
			isPlaceholder: true,
		};

	if (data.picture.thumb.url) {
		return {
			url: cacheBust(data.picture.thumb.url, data),
			isPlaceholder: false,
		};
	} else if (data.picture.pic1024.url) {
		return {
			url: cacheBust(data.picture.pic1024.url, data),
			isPlaceholder: false,
		};
	} else {
		return {
			url: AVATAR_PLACEHOLDER_SRC,
			isPlaceholder: true,
		};
	}
};

export const useAvatarSrc = (data: WithPicture | null | undefined) => {
	const avatarData = useMemo(() => getAvatarData(data), [data]);
	const [src, setSrc] = useState(avatarData.url);

	useEffect(() => {
		if (data) {
			setSrc(avatarData.url);
		}
	}, [data, src, avatarData]);

	const onError = useCallback(({ currentTarget }) => {
		currentTarget.onerror = null;
		currentTarget.src = AVATAR_PLACEHOLDER_SRC;
	}, []);

	return [src, setSrc, onError, avatarData] as const;
};
