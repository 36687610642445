import React, { type Ref, forwardRef } from "react";
import type { SVGProps } from "react";

export const PaintBrushBroadIcon = forwardRef(
	(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={32}
				height={32}
				fill="none"
				viewBox="0 0 32 32"
				className={`icon ${props.className || ""}`}
				ref={ref}
				{...props}
			>
				<path
					stroke="currentColor"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1.5}
					d="M9 4h17a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V8a4 4 0 0 1 4-4M14 19l-1 7a3 3 0 0 0 6 0l-1-7M5 14h22M21 4v5"
				/>
			</svg>
		);
	},
);
