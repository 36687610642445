import { LSKEY_LOGIN_TOKEN } from "../utils/const";

export const v2api = (
	endpoint: string,
	payload: object | null,
	callback = () => null,
) => {
	const token = localStorage.getItem(LSKEY_LOGIN_TOKEN) || null;
	const data = payload && typeof payload === "object" ? payload : {};
	if (!endpoint.includes("exsub")) {
		console.log(`
		V2API CALL 
		endpoint: ${endpoint}
		token: ${token}
		data: ${JSON.stringify(data)}
	`);
	}

	return new Promise<{ res: any; err: any }>((resolve) => {
		fetch(`/v2${endpoint}`, {
			method: "post",
			body: JSON.stringify({
				token,
				data,
			}),
			headers: {
				"Content-Type": "application/json; charset=utf-8",
				Authorization: `Bearer  ${token}`,
			},
		})
			.then((rawRes) => {
				rawRes
					.json()
					.then((res) => {
						if (res.type && res.type === "error") {
							callback(res, null);
							resolve({ res: null, err: res });
						} else {
							callback(null, res);
							resolve({ res, err: null });
						}
					})
					.catch((err) => {
						callback(err, null);
						resolve({ res: null, err });
					});
			})
			.catch((err) => {
				callback(err, null);
				resolve({ res: null, err });
			});
	});
};
