import React from "react";

const transparentClass = "bg-lg-transparent ";
//navbar-sticky headroom--not-top headroom--not-bottom headroom--pinned  navbar navbar-expand-xl navbar-light
const scrolledClasses =
	" headroom headroom--not-top headroom--not-bottom headroom--pinned ";
export const midClasses =
	" headroom headroom--not-top headroom--not-bottom headroom--pinned ";
const topClasses =
	" headroom headroom--not-top headroom--not-bottom headroom--pinned ";
const baseClasses = "navbar-transparent navbar-sticky navbar-light";

const TRANSPARENT_BG_THRESHOLD = 40;
const SCROLL_BG_THRESHOLD = 4;

export const useMainNav = () => {
	const oldScrollY = React.useRef(window.scrollY);

	const [classes, setClasses] = React.useState(
		baseClasses +
			(oldScrollY.current < TRANSPARENT_BG_THRESHOLD
				? transparentClass
				: midClasses),
	);
	const [isLightBg, setIsLightBg] = React.useState(
		true,
		//oldScrollY.current < TRANSPARENT_BG_THRESHOLD ? false : true
	);

	React.useEffect(() => {
		const handleScroll = (_e: Event) => {
			const scrollY = window.scrollY;
			if (scrollY < TRANSPARENT_BG_THRESHOLD) {
				setClasses(baseClasses + topClasses);
				// setIsLightBg(false);
			} else if (scrollY > oldScrollY.current + SCROLL_BG_THRESHOLD) {
				setIsLightBg(true);
				setClasses(baseClasses + scrolledClasses);
			} else if (scrollY < oldScrollY.current) {
				setIsLightBg(true);
				setClasses(baseClasses + midClasses);
			}

			oldScrollY.current = scrollY;
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return {
		classes,
		isLightBg,
	};
};
