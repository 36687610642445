import React, { useContext, useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Nav from "react-bootstrap/esm/Nav";
import Navbar from "react-bootstrap/esm/Navbar";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useLogout } from "../../hooks/useLogout";

import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/esm/Modal";
import { IconBg } from "../../components/ConfirmationModal/IconBg";
import SearchAll from "../../components/SearchAll";
import { ArtworkIcon } from "../../components/svg/icons/Artwork";
import { CaretRightIcon } from "../../components/svg/icons/CaretRight";
import { CloseIcon } from "../../components/svg/icons/Close";
import { ListIcon } from "../../components/svg/icons/List";
import { SearchIcon } from "../../components/svg/icons/Search";
import { AnasaeaLogo } from "../../components/svg/images/AnasaeaLogo";
import { ProfileContext } from "../../contexts/ProfileContext";
import { useAvatarSrc } from "../../hooks/useAvatarSrc";
import useBreakpoint from "../../hooks/useBreakpoints";
import { useUserMenu } from "../../hooks/useUserMenu";
import { useUserPlan } from "../../hooks/useUserPlan";
import Footer from "../../views/Footer";

const ROUTES_WITHOUT_SEARCH = [
	"/artists",
	"/search",
	"/exhibitions",
	"/artworks",
];

export interface IHeaderProps {
	user?: any;
	artist?: IArtist | null;
	classes?: string;
	isLightBg?: boolean;
}

const Header: React.FC<IHeaderProps> = ({ user, classes, isLightBg }) => {
	const breakpoint = useBreakpoint();
	const isLargeScreen = breakpoint === "xl" || breakpoint === "xxl";
	const toggleRef = useRef();
	const userPlan = useUserPlan(user);
	const logoutFn = useLogout();
	const profileContext = useContext(ProfileContext);

	const [expanded, setExpanded] = useState(false);
	const [showUpgrade, setShowUpgrade] = useState(false);
	const [anchorClasses, setAnchorClasses] = useState("");
	const handleClickNavLink = () => {
		if (expanded) {
			toggleRef.current.click();
		}
	};

	const handleLogout = (e) => {
		logoutFn(e);
		handleClickNavLink();
	};
	const location = useLocation();
	const navigate = useNavigate();

	const showUploadBtn = user && (user.galleryData || user.artistId);

	const [avatar, _, onAvatarError] = useAvatarSrc(user);

	const userMenuItems = useUserMenu(user);

	const loggedIn = React.useMemo(() => {
		if (
			breakpoint !== "xl" &&
			breakpoint !== "xxl" &&
			breakpoint !== "lg" &&
			breakpoint !== "md"
		) {
			return null;
		}

		const link =
			(user && user.galleryData && "/gallery") ||
			(user && user.artistId && "/artist") ||
			"";
		return (
			<>
				<Link to={"/profile" + link} className="order-4">
					<span
						className={
							(!isLightBg && !expanded ? "text-white" : "") +
							" d-none d-xxl-inline greeting-line"
						}
					>
						Hello, {user?.firstName}
					</span>
					<img
						src={avatar}
						style={{ objectFit: "cover" }}
						width={40}
						onError={onAvatarError}
						height={40}
						className="rounded-circle ms-3"
					/>
				</Link>
			</>
		);
	}, [user, isLightBg, expanded, breakpoint, avatar, onAvatarError]);

	const signIn = React.useMemo(
		() => (
			<Nav className="nav-noauth flex-row justify-content-center order-4 d-none d-md-flex">
				<Button
					variant="secondary"
					className={`btn-sm order-3 d-none d-md-inline`}
					style={
						breakpoint !== "xs" ? { minWidth: "150px" } : { width: "100%" }
					}
					onClick={() => navigate("/signin")}
				>
					sign in
				</Button>
				<Button
					variant="primary"
					className={`btn-sm order-3 ms-4 d-none d-md-inline`}
					style={
						breakpoint !== "xs" ? { minWidth: "150px" } : { width: "100%" }
					}
					onClick={() => navigate("/signup")}
				>
					sign up!
				</Button>
			</Nav>
		),
		[],
	);

	const signInExpanded = React.useMemo(
		() => (
			<Nav
				className={
					"pb-4 nav-noauth flex-column align-items-center d-flex d-md-none " +
					(expanded ? " order-0 " : "d-none order-4")
				}
			>
				<Button
					variant="secondary"
					className={`btn-md m-0 order-3 w-100`}
					style={
						breakpoint !== "xs" ? { minWidth: "150px" } : { width: "100%" }
					}
					onClick={() => navigate("/signin")}
				>
					sign in
				</Button>
				<Button
					variant="primary"
					className={`btn-md order-3 mt-4 w-100`}
					style={
						breakpoint !== "xs" ? { minWidth: "150px" } : { width: "100%" }
					}
					onClick={() => navigate("/signup")}
				>
					sign up!
				</Button>
			</Nav>
		),
		[expanded],
	);

	const loggedInExpanded = React.useMemo(() => {
		const link =
			(user && user.galleryData && "/gallery") ||
			(user && user.artistId && "/artist") ||
			"";
		return (
			<div
				onClick={() => navigate("/profile" + link)}
				className="d-md-none border border-1 border-color-neutral200 border-radius-s20 d-flex flex-row align-items-center mb-4 p-4"
			>
				<div className="me-4">
					<img
						src={avatar}
						style={{ objectFit: "cover" }}
						width={56}
						onError={onAvatarError}
						height={56}
						className="rounded-circle"
					/>
				</div>
				<div>
					<div className="fs-fs16 fw-bold">
						{user?.firstName} {user?.lastName}
					</div>
					<div className="fs-fs14" style={{ color: "#64748B" }}>
						{(user?.emails && user?.emails[0].address) || ""}
					</div>
				</div>
			</div>
		);
	}, [user, avatar, onAvatarError]);

	const userNav = user ? loggedIn : signIn;
	const userNavMobile = user ? loggedInExpanded : signInExpanded;

	const hideSearch = () => {
		const include = ROUTES_WITHOUT_SEARCH.some(
			(r) =>
				location.pathname.includes(r) &&
				!location.pathname.includes("/profile"),
		);
		return include;
	};
	useEffect(() => {
		// const classes = ((!isLightBg && !expanded) ? "text-white" : "py-3") + " d-lg-none d-xxl-flex d-md-flex d-xl-none hide-anchor"
		// (breakpoint !== 'xl' && breakpoint !== 'xxl'  && breakpoint !== 'lg')
		const clsses = (!expanded ? "" : "py-3 ") + " d-flex px-2 fs-fs14 tlh-sm";
		setAnchorClasses(clsses);
	}, [isLightBg, expanded]);

	const logoLink = (clsses, expandedClasses) => {
		return (
			<Link
				onClick={handleClickNavLink}
				to="/"
				className={`${clsses + (expanded ? expandedClasses : "")} align-items-center justify-content-center`}
			>
				<AnasaeaLogo
					className={`${isLightBg || expanded ? "text-color-neutral900" : "text-color-white"}`}
					width="130"
					height="40"
				/>
			</Link>
		);
	};

	const goToUpgrade = () => {
		profileContext.setShowPurchaseModal(true);
		navigate("/profile/user", { purchase: true });
	};

	const openUploadArt = () => {
		if (userPlan.isExceeded("artworks")) {
			setShowUpgrade(true);
		} else {
			navigate("/profile/artworks/add");
		}
	};

	return (
		<>
			<Navbar
				expand="md"
				variant="light"
				className={`pt-s0 ${(expanded ? "headroom-mobile-expanded" : "") + classes}`}
				collapseOnSelect
				onToggle={() => setExpanded(!expanded)}
				expanded={expanded}
			>
				<div className="w-100">
					<Container
						fluid
						className={`px-xxl-11 px-xl-6 px-3 pe-3 flex-column ${!expanded ? "flex-nowrap" : ""}`}
					>
						<div className="d-flex flex-row w-100 mb-0 align-items-center justify-content-between">
							{logoLink(
								"d-flex align-items-center order-1 mobilesize-logo px-sm-5 px-md-0",
								"order-lg-1",
							)}
							{/* search block */}
							<div
								className={`${expanded && breakpoint !== "xs" ? "" : ""} d-flex order-0 d-md-none`}
							>
								<Navbar.Toggle
									ref={toggleRef}
									aria-controls="responsive-navbar-nav"
									className={`border-0 px-1 text-color-neutral900`}
								>
									{expanded ? <CloseIcon /> : <ListIcon />}
								</Navbar.Toggle>
								{/* {logoLink("d-none d-sm-flex d-xl-none px-sm-5  order-0 order-md-1", "")} */}
							</div>
							{hideSearch() ? (
								""
							) : (
								<Link
									to="/search"
									className={`d-flex d-md-none order-2 ${isLightBg || expanded ? "text-color-neutral900" : "text-color-white"}`}
								>
									<SearchIcon />
								</Link>
							)}
							{hideSearch() ? (
								<div className="d-flex order-2 p-3"></div>
							) : (
								<div
									className={
										"searchall-container order-sm-2 d-none d-md-flex flex-grow-1 flex-nowrap" +
										(expanded ? " d-sm-none" : "")
									}
								>
									<SearchAll />
								</div>
							)}
							{showUploadBtn ? (
								<Button
									variant="primary"
									className={`ms-auto btn-sm mt-s16 mt-sm-s0 mb-s16 mb-sm-s0 order-3 d-none d-md-inline`}
									style={
										breakpoint !== "xs"
											? { minWidth: "150px" }
											: { width: "100%" }
									}
									onClick={openUploadArt}
								>
									Upload
								</Button>
							) : (
								""
							)}
							{userNav}
						</div>
						<Navbar.Collapse
							id="responsive-navbar-nav"
							className="mt-0 mt-md-0 order-2 w-100 "
						>
							<div className="d-flex flex-column flex-md-row w-100 justify-content-md-between">
								{userNavMobile}
								{showUploadBtn ? (
									<Button
										variant="primary"
										className={`${isLargeScreen ? "btn-lg" : "btn-md"} order-0 mb-4 d-inline d-md-none`}
										style={
											breakpoint !== "xs"
												? { minWidth: "150px" }
												: { width: "100%" }
										}
										onClick={openUploadArt}
									>
										Upload
									</Button>
								) : (
									""
								)}
								<div
									className="d-flex d-md-none fs-fs14 mb-4 justify-content-between mb-3"
									onClick={() => {
										navigate("/artists");
									}}
								>
									<span>Artists</span>
									<span className="border-0 bg-transparent text-color-neutral500">
										<CaretRightIcon height="14" />
									</span>
								</div>
								<div
									className="d-flex d-md-none fs-fs14 mb-4 justify-content-between mb-3"
									onClick={() => {
										navigate("/artworks");
									}}
								>
									<span>Artworks</span>
									<span className="border-0 bg-transparent text-color-neutral500">
										<CaretRightIcon height="14" />
									</span>
								</div>
								<div
									className="d-flex d-md-none fs-fs14 mb-4 justify-content-between mb-3"
									onClick={() => {
										navigate("/exhibitions");
									}}
								>
									<span>Exhibitions</span>
									<span className="border-0 bg-transparent text-color-neutral500">
										<CaretRightIcon height="14" />
									</span>
								</div>
								{/* <div className="d-flex d-md-none fs-fs14 mb-4 justify-content-between mb-3" onClick={()=> {navigate('/anasaeapopup')}}>
                  <span>Pop-Up</span>
                  <span className="border-0 bg-transparent text-color-neutral500"> 
                      <CaretRightIcon height="14"/>
                  </span>
              </div> */}
								<div
									className="d-flex d-md-none fs-fs14 mb-4 justify-content-between mb-3"
									onClick={() => {
										navigate("/thewindowandthecouch");
									}}
								>
									<span>The Window And The Couch</span>
									<span className="border-0 bg-transparent text-color-neutral500">
										<CaretRightIcon height="14" />
									</span>
								</div>
								<div
									className="d-flex d-md-none fs-fs14 mb-4 justify-content-between mb-3"
									onClick={() => {
										navigate("/pricing");
									}}
								>
									<span>Pricing</span>
									<span className="border-0 bg-transparent text-color-neutral500">
										<CaretRightIcon height="14" />
									</span>
								</div>
								<a
									className="d-flex d-md-none fs-fs14 mb-4 justify-content-between mb-3 text-color-neutral900"
									href="https://blog.anasaea.com/museums/"
								>
									<span>Museums</span>
									<span className="border-0 bg-transparent text-color-neutral500">
										<CaretRightIcon height="14" />
									</span>
								</a>
								<a
									className="d-flex d-md-none fs-fs14 mb-4 justify-content-between mb-3 text-color-neutral900"
									href="https://blog.anasaea.com"
								>
									<span>About Us</span>
									<span className="border-0 bg-transparent text-color-neutral500">
										<CaretRightIcon height="14" />
									</span>
								</a>
								<Nav className="align-items-center d-none d-md-flex flex-column flex-md-row">
									<Nav.Link
										onClick={handleClickNavLink}
										as={Link}
										to={"/artists"}
										className={anchorClasses + " ps-0"}
									>
										Artists
									</Nav.Link>
									<Nav.Link
										onClick={handleClickNavLink}
										as={Link}
										to={"/artworks"}
										className={anchorClasses}
									>
										Artworks
									</Nav.Link>
									<Nav.Link
										onClick={handleClickNavLink}
										as={Link}
										to={"/exhibitions"}
										className={anchorClasses}
									>
										Exhibitions
									</Nav.Link>
								</Nav>
								<Nav className="align-items-center d-none d-md-flex flex-column flex-md-row">
									<Nav.Link
										as={Link}
										to={"/thewindowandthecouch"}
										className={
											"whitespace-nowrap px-2 fs-fs14 tlh-sm " +
											(!expanded ? "" : "py-3")
										}
									>
										The Window And The Couch
									</Nav.Link>
									<Nav.Link
										onClick={handleClickNavLink}
										as={Link}
										to="/pricing"
										className={"fs-fs14 tlh-sm" + (!expanded ? "" : " py-3")}
									>
										Pricing
									</Nav.Link>
									<Nav.Link
										onClick={handleClickNavLink}
										href="https://blog.anasaea.com/museums/"
										className={"fs-fs14 tlh-sm" + (!expanded ? "" : "py-3")}
									>
										Museums
									</Nav.Link>
									<Nav.Link
										onClick={handleClickNavLink}
										href="https://blog.anasaea.com"
										className={
											"fs-fs14 tlh-sm pe-0" + (!expanded ? "" : "py-3")
										}
									>
										About Us
									</Nav.Link>

									<Nav.Link
										onClick={handleClickNavLink}
										className={
											(!expanded ? "text-white" : "") +
											(user ? " d-none d-xxl-block greeting-devider" : "")
										}
									>
										{" "}
										|{" "}
									</Nav.Link>

									{expanded && user && (
										<>
											<div
												className="mt-s24 mb-s24 w-100"
												style={{
													maxWidth: "71px",
													background: "#EEEFF2",
													minHeight: "1px",
												}}
											></div>
											{userMenuItems.map((item) => (
												<Nav.Link
													onClick={handleClickNavLink}
													key={item.id}
													as={Link}
													to={item.to}
													className={
														(!isLightBg && !expanded ? "text-white" : "py-3") +
														` ${!expanded ? "" : ""}`
													}
												>
													{item.title}
												</Nav.Link>
											))}

											<Nav.Link
												as={Link}
												to="#"
												onClick={handleLogout}
												className={
													(!isLightBg && !expanded ? "text-white" : "py-3") +
													` ${!expanded ? "" : ""}`
												}
											>
												Logout
											</Nav.Link>
										</>
									)}
								</Nav>
								{user ? (
									<Button
										variant="secondary"
										className={`${isLargeScreen ? "btn-lg" : "btn-md"} order-0 mb-4 d-inline d-md-none`}
										style={
											breakpoint !== "xs"
												? { minWidth: "180px" }
												: { width: "100%" }
										}
										onClick={handleLogout}
									>
										Logout
									</Button>
								) : (
									""
								)}
							</div>
						</Navbar.Collapse>
					</Container>
				</div>

				{expanded ? (
					<div className="d-flex d-xl-none mt-5">
						<Footer noFooterTag />
					</div>
				) : (
					""
				)}
				<Modal
					show={showUpgrade}
					onHide={() => setShowUpgrade(false)}
					centered
					className="ps-sm-s0 confirm-modal confirmation-modal-1"
				>
					<Modal.Body>
						<div>
							<div className="d-flex align-items-center justify-content-center">
								<div className="position-relative">
									<IconBg className="text-color-warningBase" />
									<span
										className="absolute"
										style={{
											top: "13px",
											left: "13px",
											position: "absolute",
										}}
									>
										<ArtworkIcon
											width={24}
											height={24}
											className="text-color-white"
										/>
									</span>
								</div>
							</div>

							<p className="thead6 fw-medium mb-s0 mt-s16 text-center">
								Subscribe to ANASAEA
							</p>
							<p className="tbodymd text-color-neutral600 mt-4 text-center">
								We've enjoyed your artworks, but the limit on adding new
								artworks under the free plan has been exhausted. Please consider
								subscribing to one of our plans to get endless artistic
								opportunities.
							</p>

							<div className="d-flex align-items-center justify-content-between mt-s32">
								<Button
									variant="secondary"
									className="btn-lg w-100"
									onClick={() => setShowUpgrade(false)}
								>
									Cancel
								</Button>

								<Button
									variant="primary"
									className="ms-s16 w-100 btn-lg d-flex align-items-center justify-content-center"
									onClick={goToUpgrade}
								>
									<span className="me-s8">Upgrade</span>
								</Button>
							</div>
						</div>
					</Modal.Body>
				</Modal>
			</Navbar>
		</>
	);
};

export default Header;
