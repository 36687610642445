const EMAIL_RE =
	/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const YOUTUBE_RE =
	/^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|live\/|v\/)?)([\w\-]+)(\S+)?$/;

const NICKNAME_RE = /^[a-zA-Z0-9-]+$/; // alphanumeric, can include or -, no spaces

const URL_RE =
	/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

// const PASSWORD_RE = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]{8})$/;

export type TRule = {
	name: string;
	message: string;
	// value: value of field being validated
	// values: all form values for relational validation
	fn(value: any, values: any): boolean | { [key: string]: string };
};

export type TValidationSchema<T> = { [key in keyof T]?: TRule[] };

export function validationSchema<T>(schema: TValidationSchema<T>) {
	return function (values: T) {
		const errors: {
			[key in keyof T]?: string;
		} = {};

		Object.keys(schema).forEach((key) => {
			const fieldName = key as keyof T;
			const fieldRules = schema[fieldName];
			fieldRules?.forEach((rule) => {
				if (!rule.fn(values[fieldName], values)) {
					errors[fieldName] = rule.message;
				}
			});
		});

		return errors;
	};
}

export const RequiredRule: TRule = {
	name: "Required",
	message: "Required",
	fn: (value: any) => !!value && !!String(value).trim(),
};

export const RequiredValueRule: TRule = {
	name: "Required",
	message: "Required",
	fn: (value: any) => !!value,
};

export const RequiredHolderRule: TRule = {
	name: "Required",
	message: "Please enter account holder name",
	fn: (value: any) => !!value && !!String(value).trim(),
};
export const RequiredBankRule: TRule = {
	name: "Required",
	message: "Please enter bank name",
	fn: (value: any) => !!value && !!String(value).trim(),
};
export const RequiredIbanRule: TRule = {
	name: "Required",
	message: "Please enter iban",
	fn: (value: any) => !!value && !!String(value).trim(),
};
export const RequiredSwiftRule: TRule = {
	name: "Required",
	message: "Please enter swift",
	fn: (value: any) => !!value && !!String(value).trim(),
};
export const RequiredAccountRule: TRule = {
	name: "Required",
	message: "Please enter account number",
	fn: (value: any) => !!value && !!String(value).trim(),
};

// TODO, migrate to this validator in every form
export const RequiredRule2 = (message: string = "Required") => ({
	name: "Required",
	message,
	fn: (value: any) => !!value && !!String(value).trim(),
});

export const ConditionalRequiredRule = <FormValues>(
	condition: (values: FormValues) => boolean,
	message: string = "Required",
) => ({
	name: "RequiredIfRule",
	message,
	fn: (value: any, values: any) =>
		condition(values) ? !!value && !!String(value).trim() : true,
});

export const SameValueAs = (fieldName: string, message: string) => ({
	name: "Required",
	message,
	fn: (value: any, values: any) =>
		value === values[fieldName as keyof typeof values],
});
export const EmailRule: TRule = {
	name: "Email",
	message: "Inrorrect email address",
	fn: (value: any) => (value ? RegExp(EMAIL_RE).test(value) : true),
};
export const NicknameRule: TRule = {
	name: "Nickname",
	message: "Only latin, numbers and hyphens are allowed",
	fn: (value: any) => (value ? RegExp(NICKNAME_RE).test(value) : true),
};

export const EmailRequiredRule: TRule = {
	name: "Email",
	message: "Inrorrect email address",
	fn: (value: any) => (value ? RegExp(EMAIL_RE).test(value) : false),
};

export const YouTubeLnkRule: TRule = {
	name: "YouTubeLink",
	message: "Only YouTube links are accepted",
	fn: (value: any) => (value ? RegExp(YOUTUBE_RE).test(value) : true),
};

export const LinkRule = {
	name: "Link",
	message: "Please enter valid url",
	fn: (value: any) => (value.trim() ? RegExp(URL_RE).test(value.trim()) : true),
};

export const PasswordRule: TRule = {
	name: "Password",
	message: "Password should be at least 8 characters",
	fn: (value: any) => value.length >= 8,
	// fn: (value: any) => (value ? RegExp(PASSWORD_RE).test(value) : true),
};

export const GalleryPasswordRule: TRule = {
	name: "GalleryPassword",
	message: "Password must have at least 4 characters",
	fn: (value: any) => value.length >= 4,
};

export const RequiredTitleRule: TRule = {
	name: "Title",
	message: "Please enter a title of you artwork",
	fn: (value: any) => !!value && !!String(value).trim(),
};
export const RequiredYearRule: TRule = {
	name: "Year",
	message: "Please enter an year created of you artwork",
	fn: (value: any) => !!value && !!String(value).trim(),
};
export const RequiredFirstNameRule: TRule = {
	name: "FirstName",
	message: "Please enter artist first name",
	fn: (value: any) => !!value && !!String(value).trim(),
};
export const RequiredLastNameRule: TRule = {
	name: "LastName",
	message: "Please enter artist last name",
	fn: (value: any) => !!value && !!String(value).trim(),
};
