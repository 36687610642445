import React from "react";
import { createRoot } from "react-dom/client";
import { Route, Routes, useNavigate } from "react-router-dom";
import { SuspenseRouteWrapper } from "./ui/components/RouteWrapper";
import { REACT_ROOT } from "./ui/const";
import { Providers } from "./ui/contexts/Providers";
import { usePublicSettings } from "./ui/contexts/PublicSettings";
import "./main.scss";

import {
	EmptyLayout,
	FullPageLayout,
	FullPageLayoutNoFooter,
	HomePageLayout,
	MainLayout,
} from "./ui/layouts";

const GalleryProfilePublic = React.lazy(
	() => import("./ui/views/GalleryProfilePublic"),
);
const ArtPieceView = React.lazy(() => import("./ui/views/ArtPieceView"));

const MainLayoutRoutes = [
	{
		path: "/profile/*",
		component: React.lazy(() => import("./ui/views/Profile")),
	},
	{
		path: "/galleryProfile/:id",
		component: GalleryProfilePublic,
	},

	{
		path: "/gallery/:id",
		component: GalleryProfilePublic,
	},

	{
		path: "/viewArtPiece/:id/:stripeRes",
		component: ArtPieceView,
	},

	{
		path: "/viewArtPiece/:id",
		component: ArtPieceView,
	},

	{
		path: "/all-products",
		component: React.lazy(() => import("./ui/views/AllProducts")),
	},

	{
		path: "/digital_twin",
		component: React.lazy(() => import("./ui/views/DigitalTwin")),
	},

	{
		path: "/pricing/*",
		component: React.lazy(() => import("./ui/views/Pricing")),
	},

	{
		path: "/editExhibition/:id",
		component: React.lazy(() => import("./ui/views/GalleryEditor")),
	},

	{
		path: "/artist/:id",
		component: React.lazy(() => import("./ui/views/ArtistProfilePublic")),
	},

	{
		path: "/collection/:id",
		component: React.lazy(() => import("./ui/views/UserListPublic")),
	},

	{
		path: "/exhibitions",
		component: React.lazy(() => import("./ui/views/Pages/GalleriesPage")),
	},

	{
		path: "/artists",
		component: React.lazy(() => import("./ui/views/Pages/ArtistsPage")),
	},

	{
		path: "/artworks",
		component: React.lazy(() => import("./ui/views/Pages/ArtWorksPage")),
	},

	{
		path: "/search",
		component: React.lazy(() => import("./ui/views/AllGalleries")),
	},
];

const FullPageLayoutRoutes = [
	{
		path: "/reset-password/:token",
		component: React.lazy(() => import("./ui/views/ResetPassword")),
	},

	{
		path: "/signin",
		component: React.lazy(() => import("./ui/views/SignIn")),
	},

	{
		path: "/signup",
		component: React.lazy(() => import("./ui/views/SignUp")),
	},

	{
		path: "/forgot-password",
		component: React.lazy(() => import("./ui/views/ForgotPassword")),
	},
];

const GalleryPage = React.lazy(() => import("./ui/views/GalleryPage"));

const FullPageLayoutNoFooterRoutes = [
	{
		path: "/viewExhibition/:id",
		component: GalleryPage,
	},

	{
		path: "/gettingStarted/:step/*",
		component: React.lazy(() => import("./ui/views/GettingStarted")),
	},
];

const AdminApp = React.lazy(() => import("./ui/admin-app"));

const App = () => {
	const settings = usePublicSettings();

	const specialExhibitions =
		settings && settings.specialExhibitions
			? Object.keys(settings.specialExhibitions)
			: [];

	const viewGalleryRedirect = () => {
		const ViewGalleryRedirect = () => {
			const navigate = useNavigate();
			navigate(window.location.href.replace("viewGallery", "viewExhibition"));
			return null;
		};

		return <Route path="/viewGallery/:id" element={<ViewGalleryRedirect />} />;
	};

	return (
		<>
			<Routes location={location}>
				<Route
					path="/admin/*"
					element={
						<>
							<SuspenseRouteWrapper
								layout={MainLayout}
								component={AdminApp}
							/>
						</>
					}
				/>

				<Route
					path="/oauthredirect/*"
					element={
						<SuspenseRouteWrapper
							layout={false}
							component={React.lazy(() => import("./ui/views/OauthRedirect"))}
						/>
					}
				/>

				<Route
					path="/"
					element={
						<SuspenseRouteWrapper
							layout={HomePageLayout}
							component={React.lazy(() => import("./ui/views/HomePage"))}
						/>
					}
				/>

				{viewGalleryRedirect()}

				{MainLayoutRoutes.map((r) => (
					<Route
						path={r.path}
						element={
							<SuspenseRouteWrapper
								layout={MainLayout}
								component={r.component}
							/>
						}
					/>
				))}

				{FullPageLayoutNoFooterRoutes.map((r) => (
					<Route
						path={r.path}
						element={
							<SuspenseRouteWrapper
								layout={FullPageLayoutNoFooter}
								component={r.component}
							/>
						}
					/>
				))}

				{FullPageLayoutRoutes.map((r) => (
					<Route
						path={r.path}
						element={
							<SuspenseRouteWrapper
								layout={FullPageLayout}
								component={r.component}
							/>
						}
					/>
				))}

				{specialExhibitions.map((k) => (
					<Route
						path={`/${k}`}
						element={
							<SuspenseRouteWrapper
								layout={EmptyLayout}
								component={GalleryPage}
							/>
						}
					/>
				))}
			</Routes>
		</>
	);
};

const container = document.getElementById(REACT_ROOT);
const root = createRoot(container!);

root.render(
	<Providers>
		<App />
	</Providers>,
);
