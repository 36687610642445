import { useEffect, useState } from "react";
import { v2api } from "../actions/v2api";

export const useArtistById = (aid: string | null | undefined) => {
	const [artist, setArtist] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (aid) {
			// @ts-ignore
			v2api("/front/exsubArtistById", { id: aid }, (err, res) => {
				setLoading(false);
				setArtist(res || null);
			});
		}
	}, [aid]);

	return { isLoading: loading, artist };
};
