export const pushDataLayer = (data: any) => {
	if (!data) {
		return console.warn("You must provide a data to push to data layer");
	}

	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push(data);
};

export const gaEventSearchFinished = (searchText?: string | null) => {
	pushDataLayer({
		event: "searchFinished",
		searchText,
	});
};

export const gaEventSearchStarted = () => {
	pushDataLayer({
		event: "searchStarted",
	});
};

export const gaEventPurchasePlanStarted = () => {
	pushDataLayer({
		event: "purchasePlanStarted",
	});
};

export const gaEventStartedUpgrade = ({ category, userId, plan }) => {
	pushDataLayer({
		event: "started_upgrade",
		category,
		user_id: userId,
		origin: window.location.origin,
		plan_id: plan,
	});
};

export const gaEventPurchasePlanMethodSelected = ({
	product,
	period,
	price,
}) => {
	pushDataLayer({
		event: "purchasePlanMethodSelected",
		product,
		duration: period,
		price,
	});
};

export const gaEventPurchasePlanDurationSelected = ({ period }) => {
	pushDataLayer({
		event: "purchasePlanMethodSelected",
		duration: period,
	});
};

export const gaEventPurchasePlanSelected = ({ product }) => {
	pushDataLayer({
		event: "purchasePlanMethodSelected",
		product,
	});
};

export const gaEventVoidUserId = ({ userId }) => {
	pushDataLayer({
		userId,
	});
};

export const gaEventFreshLoad = ({ userId }) => {
	pushDataLayer({
		event: "freshLoad",
		userId,
	});
};

export const gaEventPurchaseArtSuccessful = ({
	artistId,
	artistName,
	artworkId,
	price,
}) => {
	pushDataLayer({
		event: "purchaseArtSuccessful",
		artistID: artistId,
		artistName: artistName,
		artID: artworkId,
		price,
	});
};

export const gaEventArtistPageArtVisited = ({
	artistId,
	artistName,
	artworkId,
	artworkTitle,
}) => {
	pushDataLayer({
		event: "artistPageArtVisited",
		artistID: artistId,
		artistName: artistName,
		artName: artworkTitle,
		artID: artworkId,
	});
};

export const gaEventPurchaseArtUnsuccessful = ({
	artistId,
	artistName,
	artworkId,
	price,
}) => {
	pushDataLayer({
		event: "purchaseArtUnsuccessful",
		artistID: artistId,
		artistName: artistName,
		artID: artworkId,
		price,
	});
};

export const gaEventPurchaseArt1stStep = () => {
	pushDataLayer({
		event: "purchaseArt1stStep",
	});
};

export const gaEventExhibitionCreated = ({
	exhibitionId,
	userId,
	userType,
	artistId,
}) => {
	pushDataLayer({
		event: "exhibitionCreated",
		exhibitionId,
		userId,
		userType,
		artistId,
	});
};

export const gaEventSubscriptionPurchased = ({
	category,
	userId,
	value,
	currency,
	planId,
}) => {
	pushDataLayer({
		event: "subscription_purchased",
		category,
		value,
		currency,
		user_id: userId,
		plan_id: planId,
	});
};

export const gaEventPurchasePlanSuccessful = ({
	plan,
	duration,
	price,
	userId,
}) => {
	pushDataLayer({
		event: "purchasePlanSuccessful",
		product: plan,
		duration,
		userId: userId,
		price,
	});
};

export const gaEventPurchasePlanUnsuccessful = ({ userId }) => {
	pushDataLayer({
		event: "purchasePlanUnsuccessful",
		userId,
	});
};

export const gaEventSignUpStarted = ({ signUpToken }) => {
	pushDataLayer({
		event: "signUpStarted",
		signUpToken,
	});
};

export const gaEventSignUpUnsuccessful = ({ signUpToken, userType }) => {
	pushDataLayer({
		event: "signUpUnsuccessful",
		userDescription: userType,
		signUpToken,
	});
};

export const gaEventNewUser = ({ signUpToken, userType }) => {
	pushDataLayer({
		event: "new user",
		category: "User Registration",
		label: userType,
		origin: window.location.origin,
		signUpToken,
	});
};

export const gaEventSignUpSuccessful = ({ userType, signUpToken }) => {
	pushDataLayer({
		event: "signUpSuccessful",
		userDescription: userType,
		signUpToken,
	});
};

export const gaEventSignUpFacebookSuccessful = ({ userType, signUpToken }) => {
	pushDataLayer({
		event: "signUpFacebookSuccessful",
		userDescription: userType,
		signUpToken,
	});
};

export const gaEventAuthFlowContinue1 = ({ signUpToken }) => {
	pushDataLayer({
		event: "authFlowContinue1",
		signUpToken,
	});
};

export const gaEventAuthFlowContinue2 = ({ userType, signUpToken }) => {
	pushDataLayer({
		event: "authFlowContinue2",
		userDescription: userType,
		signUpToken,
	});
};

export const gaEventAuthFlowContinue3 = ({ signUpToken }) => {
	pushDataLayer({
		event: "authFlowContinue3",
		signUpToken,
	});
};

export const gaEventPurchaseArt2ndStep = ({
	artistId,
	artworkId,
	artistName,
}) => {
	pushDataLayer({
		event: "purchaseArt2ndStep",
		artistID: artistId,
		artID: artworkId,
		artistName,
	});
};

export const gaEventPurchaseArt3rdStep = ({
	artistId,
	artworkId,
	artistName,
}) => {
	pushDataLayer({
		event: "purchaseArt3rdStep",
		artistID: artistId,
		artID: artworkId,
		artistName,
	});
};

export const gaEventPurchaseArt4thStep = ({
	artistId,
	artworkId,
	artistName,
}) => {
	pushDataLayer({
		event: "purchaseArt4thStep",
		artistID: artistId,
		artID: artworkId,
		artistName,
	});
};

export const gaEventSignIn = ({ userId }) => {
	pushDataLayer({
		event: "sign_in",
		category: "Users",
		user_id: userId,
		origin: window.location.origin,
	});
};

export const gaEventSignInFacebook = ({ userId }) => {
	pushDataLayer({
		event: "sign_in_facebook",
		category: "Users",
		user_id: userId,
		origin: window.location.origin,
	});
};

export const gaEventArtistView = ({ artistId, artistName }) => {
	pushDataLayer({
		event: "artist_view",
		category: "Artist",
		artist_id: artistId,
		artistName,
		origin: window.location.origin,
	});
};

export const gaEventArtistPageVisited = ({ artistId, artistName }) => {
	pushDataLayer({
		event: "artistPageVisited",
		artistID: artistId,
		artistName,
	});
};

export const gaEventArtistPageShowMore = ({ artistId, artistName }) => {
	pushDataLayer({
		event: "artistPageShowMore",
		artistID: artistId,
		artistName,
	});
};

export const gaEvent3dGalleryView = ({ galId }) => {
	pushDataLayer({
		event: "3dgallery_view",
		category: "3DGallery",
		gallery_id: galId,
		origin: window.location.origin,
	});
};

export const gaEventGalleryOpened = ({ galId }) => {
	pushDataLayer({
		event: "galleryOpened",
		galleryID: galId,
	});
};

export const gaEventBannerClick = ({ bannerId }) => {
	pushDataLayer({
		event: "bannerClick",
		bannerID: bannerId,
	});
};

export const gaEventContactUsDigitalTwin = () => {
	pushDataLayer({
		event: "contactUsDigitalTwin",
	});
};

export const gaEventContactUsDigitalTwinFailed = () => {
	pushDataLayer({
		event: "contactUsDigitalTwinFailed",
	});
};

export const gaEventSignUpDigitalTwinStart = () => {
	pushDataLayer({
		event: "signUpDigitalTwinStart",
	});
};

export const gaEventPurchaseItemMakeOffer = ({
	artistId,
	artistName,
	artId,
	price,
	text,
}) => {
	pushDataLayer({
		event: "purchaseItemMakeOffer",
		artistID: artistId,
		artistName,
		artID: artId,
		offerAmount: price,
		offerComment: text,
	});
};

export const gaEventGalleryView = ({ galId }) => {
	pushDataLayer({
		event: "gallery_view",
		category: "Gallery",
		gallery_id: galId,
		origin: window.location.origin,
	});
};

export const gaEventTalkToAmbassador = () => {
	pushDataLayer({
		event: "talkToAmbassador",
	});
};

export const gaEventTalkToAmbassadorFailed = () => {
	pushDataLayer({
		event: "talkToAmbassadorFailed",
	});
};

export const gaEventTalkDigitalTwin = () => {
	pushDataLayer({
		event: "talkDigitalTwin",
	});
};

export const gaEventTalkDigitalTwinFailed = () => {
	pushDataLayer({
		event: "talkDigitalTwinFailed",
	});
};

export const gaEventGalleryAddedArtist = ({ galleryId, artistId }) => {
	pushDataLayer({
		event: "galleryAddedArtist",
		galleryId,
		artistId,
	});
};

export const gaEventArtworkAdded = ({ userId, artistId, artworkId }) => {
	pushDataLayer({
		event: "artworkAdded",
		userId,
		artistId,
		artworkId,
	});
};
