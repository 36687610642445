import React, { useContext, useEffect, useState } from "react";
import { v2api } from "../actions/v2api";
import { useToastContext } from "./Toast";
export const PublicSettingsContext = React.createContext({} as any);

export function PublicSettingsProvider({ children }) {
	const toast = useToastContext();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState({});

	useEffect(() => {
		v2api("/users/settings", null, (err, res) => {
			if (err || !res) {
				setLoading(false);
				toast.error("Critical error: no settings defined");
				return;
			}

			const constants = {
				WINDOW_COUCH_EXHIBITION_ID: res.specialExhibitions.thewindowandthecouch,
				POPUP_EXHIBITION_ID: res.specialExhibitions.anasaeapopup,
				DSL_EXHIBITION_ID: res.specialExhibitions.dslcollection,
				LIAN_EXHIBITION_ID: res.specialExhibitions.lianfoundation,
			};
			console.log("constants", constants);
			setData({ ...res, constants });
			setLoading(false);
		});
	}, []);

	if (loading) {
		return null;
	}
	return (
		<PublicSettingsContext.Provider value={{ ...data, loading }}>
			{children}
		</PublicSettingsContext.Provider>
	);
}

export const usePublicSettings = () => useContext(PublicSettingsContext);
