import React, { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/esm/Form";
import InputGroup from "react-bootstrap/esm/InputGroup";
import Modal from "react-bootstrap/esm/Modal";
import { v2api } from "../../../actions/v2api";
import { CloseIcon } from "../../../components/svg/icons/Close";
import { SearchIcon } from "../../../components/svg/icons/Search";
import { useCurrentUserContext } from "../../../contexts/CurrentUser";
import { useUserListsByUserId } from "../../../hooks";
import { EditListModal } from "../CreateListModal";

export interface IAddToListModalProps {
	isOpen: boolean;
	onClose: () => void;
	artworks: () => void;
}

type TActiveModals = "add_item" | "create_list";

export default function AddToListModal({
	isOpen,
	onClose,
	artworks,
}: IAddToListModalProps) {
	const { userId, refresh } = useCurrentUserContext();
	const [search, setSearch] = useState("");
	const [touched, setTouched] = useState(false);
	const [selectedItems, setSelectedItems] = useState([]);

	const [activeModal, setActiveModal] = useState<TActiveModals>();
	const {
		userLists,
		loading,
		refresh: refreshUserLists,
	} = useUserListsByUserId(userId, { search });
	const [focused, setFocused] = useState(false);

	useEffect(() => {
		if (activeModal === "add_item") {
			refreshUserLists();
			refresh();
		}
	}, [activeModal]);

	useEffect(() => {
		if (!loading && artworks[0] && userLists.length > 0) {
			const artwork = artworks[0];
			const preselectedItems: string[] = userLists.reduce((acc, i) => {
				if (i.artworks.includes(artwork)) {
					return [...acc, i._id];
				}

				return acc;
			}, []);

			if (preselectedItems.length === 0) {
				setSelectedItems([userLists[0]._id]);
			} else {
				setSelectedItems(preselectedItems);
			}
		}
	}, [artworks, userLists, loading]);

	const handleCreateNewCollection = useCallback(() => {
		setActiveModal("create_list");
	}, []);

	const handleReturnToAddItemModal = useCallback(() => {
		setActiveModal("add_item");
	}, []);

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setSearch(value);
	};

	const handleSave = () => {
		console.log("selectedItems", selectedItems);
		const userListIds = [...new Set(selectedItems)]; // only unique ids
		const artworkId = artworks[0]; // @TODO remove array artworks for value
		console.log("userListIds, artworkId", userListIds, artworkId);
		v2api("/user-list/addItem", { userListIds, artworkId }, (error?: Error) => {
			if (error && error.message) {
				console.error("Error adding artwork to list", error.message);
				return;
			}

			console.info("Added item in list", artworkId, userListIds);
			onClose();
		});
	};

	useEffect(() => {
		if (!loading) {
			if (userLists.length === 0) {
				handleCreateNewCollection();
			} else {
				handleReturnToAddItemModal();
			}
		}
	}, [loading, userLists]);

	if (!isOpen) return null;

	return (
		<>
			{activeModal === "add_item" && (
				<Modal
					centered
					show={activeModal === "add_item"}
					onHide={onClose}
					className="add-to-list-modal"
				>
					<div className="p-s36">
						<div className="d-flex align-items-center justify-content-between">
							<p className="thead5 fw-semibold mb-s0">Add to collection</p>
							<Button
								variant="secondary"
								className="btn-md"
								style={{ minWidth: "140px" }}
								onClick={handleCreateNewCollection}
							>
								Create
							</Button>
						</div>

						<div className="mt-s16">
							<InputGroup
								className="w-100 search-input mt-md-0"
								style={{ height: "56px" }}
							>
								<Form.Control
									type="text"
									onBlur={() => {
										setFocused(false);
									}}
									onFocus={() => {
										setFocused(true);
									}}
									onChange={handleSearch}
									placeholder="Search collection name"
								/>
								<InputGroup.Text
									id="basic-addon"
									className={((focused && "focused ") || "") + " pe-3"}
								>
									{search.length > 0 ? (
										<CloseIcon
											width={24}
											height={24}
											onClick={() => setSearch("")}
											role="button"
										/>
									) : (
										<SearchIcon width={24} height={24} />
									)}
								</InputGroup.Text>
							</InputGroup>
						</div>
						<div
							className="mt-s24 scrollbar"
							style={{
								minHeight: "254px",
								maxHeight: "254px",
								overflowY: "scroll",
							}}
						>
							{!loading &&
								userLists &&
								userLists.length > 0 &&
								userLists.map((ul, idx) => {
									const isFirst = idx === 0;
									const isActive = !!selectedItems.find((si) => si === ul._id);
									const handleChange = () => {
										setTouched(true);
										setSelectedItems(
											(prev) =>
												!isActive
													? [...prev, ul._id] // select
													: prev.filter((psi) => psi !== ul._id), // unselect
										);
									};
									return (
										<Form.Group
											onClick={handleChange}
											key={ul._id}
											className={`${isFirst ? "" : "mt-s16"} user-select-none cursor-pointer p-s24 d-flex align-items-center justify-content-between border border-1 border-radius-s16 border-color-${isActive ? "primary700" : "neutral400"}`}
										>
											<Form.Label className="tbodylg fw-semibold mb-s0">
												{ul.name}
											</Form.Label>
											<Form.Check
												className="green-check"
												type="checkbox"
												checked={isActive}
												onChange={handleChange}
											/>
										</Form.Group>
									);
								})}

							{!loading && userLists && userLists.length === 0 && (
								<div className="mt-s24 d-flex align-items-center justify-content-center">
									<p className="tbodymd fw-medium">
										No collections created yet.
									</p>
								</div>
							)}
						</div>

						<div className="mt-s24 d-flex align-items-center justify-content-between">
							<Button
								variant="secondary"
								className="btn-lg w-50 me-s24"
								onClick={onClose}
							>
								Cancel
							</Button>

							<Button
								variant="primary"
								className="btn-lg w-50"
								disabled={!selectedItems || selectedItems.length === 0}
								onClick={handleSave}
							>
								Save
							</Button>
						</div>
					</div>
				</Modal>
			)}

			{activeModal === "create_list" && (
				<EditListModal
					isOpen={activeModal === "create_list"}
					onClose={
						!loading && userLists.length === 0
							? onClose
							: handleReturnToAddItemModal
					}
					onSuccess={handleReturnToAddItemModal}
				/>
			)}
		</>
	);
}
