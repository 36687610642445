import React, { type Ref, forwardRef } from "react";
import type { SVGProps } from "react";

export const TwitterIcon = forwardRef(
	(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={32}
				height={32}
				fill="none"
				viewBox="0 0 32 32"
				className={`icon ${props.className || ""}`}
				ref={ref}
				{...props}
			>
				<path
					stroke="currentColor"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M29.333 5.347c-1.334.653-2.64.918-4 1.32-1.495-1.687-3.711-1.78-5.84-.983-2.13.797-3.524 2.747-3.494 4.983V12c-4.326.11-8.18-1.86-10.666-5.333 0 0-5.576 9.91 5.333 14.666-2.496 1.663-4.985 2.784-8 2.667 4.41 2.404 9.217 3.23 13.379 2.023 4.773-1.387 8.696-4.964 10.201-10.323.45-1.63.672-3.313.663-5.004 0-.332 2.013-3.696 2.424-5.35z"
				/>
			</svg>
		);
	},
);
