import React from "react";
import { BrowserRouter } from "react-router-dom";
import { AddToUserListProvider } from "./AddToUserList";
import { CurrentUserContextProvider } from "./CurrentUser";
import { GlobalTracker } from "./GlobalTracker";
import { ProfileContextProvider } from "./ProfileContext";
import { PublicSettingsProvider } from "./PublicSettings";
import { ToastContextProvider } from "./Toast";

export interface IProvidersProps {
	children?: React.ReactNode;
}

export const Providers: React.FC<IProvidersProps> = ({ children }) => {
	return (
		<BrowserRouter>
			<ToastContextProvider>
				<PublicSettingsProvider>
					<CurrentUserContextProvider>
						<ProfileContextProvider>
							<GlobalTracker>
								<AddToUserListProvider>{children}</AddToUserListProvider>
							</GlobalTracker>
						</ProfileContextProvider>
					</CurrentUserContextProvider>
				</PublicSettingsProvider>
			</ToastContextProvider>
		</BrowserRouter>
	);
};
