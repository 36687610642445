export interface ISubscriptionPlan {
	name: string;
	maxNumOfArtists: number;
	maxNumOfGalleries: number;
	maxNumOfArtPieces: number;
	label: string;
	perMonth: number;
	perYear: number;
	isInternal?: boolean;
}

export interface ISubscriptionActuals {
	totalArtists: number;
	totalGalleries: number;
	totalArtPieces: number;
	validTill?: Date;
}

export const DEFAULT_CURATOR_PLAN_NAME = "FreeGallery";
export const DEFAULT_ARTIST_PLAN_NAME = "FreeArtist";
export const DEFAULT_USER_PLAN_NAME = "FreeCollector";

export const FREE_CURATOR_PLAN_TBD = {
	// tbd
};

export const ADVANCED_CURATOR_PLAN: ISubscriptionPlan = {
	name: "AdvancedCurator",
	maxNumOfArtists: 7,
	maxNumOfGalleries: 5,
	maxNumOfArtPieces: 150,
	label: "Advanced Gallery",
	perMonth: 70,
	perYear: 59,
};

export const PREMIUM_CURATOR_PLAN: ISubscriptionPlan = {
	name: "PremiumCurator",
	maxNumOfArtists: 15,
	maxNumOfGalleries: 10,
	maxNumOfArtPieces: -1,
	label: "Premium Gallery",
	perMonth: 130,
	perYear: 99,
};

export const FREE_ARTIST_PLAN_TBD = {
	// tbd
};

export const PREMIUM_ARTIST_PLAN: ISubscriptionPlan = {
	name: "PremiumArtist",
	maxNumOfArtists: 1,
	maxNumOfGalleries: 3,
	maxNumOfArtPieces: -1,
	label: "Premium Artist",
	perMonth: 29.95,
	perYear: 24.95,
};

export const AMBASSADOR_ARTIST_PLAN: ISubscriptionPlan = {
	name: "AmbassadorArtist",
	maxNumOfArtists: 1,
	maxNumOfGalleries: 6,
	maxNumOfArtPieces: -1,
	label: "Ambassador Artist",
	perMonth: 39.0,
	perYear: 29.95,
	isInternal: true,
};

export const ADVANCED_ARTIST_PLAN: ISubscriptionPlan = {
	name: "AdvancedArtist",
	maxNumOfArtists: 1,
	maxNumOfGalleries: 2,
	maxNumOfArtPieces: 50,
	label: "Advanced Artist",
	perMonth: 12.95,
	perYear: 9.95,
};
export const StandardPlans: ISubscriptionPlan[] = [
	{
		name: "AdminCurator", // everything unlimited
		maxNumOfArtists: -1,
		maxNumOfGalleries: -1,
		maxNumOfArtPieces: -1,
		label: "Admin Infinite",
		perMonth: 0,
		perYear: 0,
	},
	{
		name: DEFAULT_CURATOR_PLAN_NAME,
		maxNumOfArtists: 1,
		maxNumOfGalleries: 1,
		maxNumOfArtPieces: 25,
		label: "Free Gallery",
		perMonth: 0,
		perYear: 0,
	},
	{
		name: DEFAULT_ARTIST_PLAN_NAME,
		maxNumOfArtists: 1,
		maxNumOfGalleries: 1,
		maxNumOfArtPieces: 10,
		label: "Free artist",
		perMonth: 0,
		perYear: 0,
	},
	{
		name: DEFAULT_USER_PLAN_NAME,
		maxNumOfArtists: 0,
		maxNumOfGalleries: 0,
		maxNumOfArtPieces: 0,
		label: "Free collector",
		perMonth: 0,
		perYear: 0,
	},
	{
		name: "BasicCurator",
		maxNumOfArtists: 5,
		maxNumOfGalleries: 5,
		maxNumOfArtPieces: 100,
		label: "Basic Curator",
		perMonth: 0,
		perYear: 0,
	},
	ADVANCED_ARTIST_PLAN,
	PREMIUM_ARTIST_PLAN,
	ADVANCED_CURATOR_PLAN,
	PREMIUM_CURATOR_PLAN,
	AMBASSADOR_ARTIST_PLAN,
];
