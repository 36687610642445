import React from "react";

export const Exclaim = ({ style }: any) => (
	<svg
		style={style}
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M8.00065 15.1668C4.04732 15.1668 0.833984 11.9535 0.833984 8.00016C0.833984 4.04683 4.04732 0.833496 8.00065 0.833496C11.954 0.833496 15.1673 4.04683 15.1673 8.00016C15.1673 11.9535 11.954 15.1668 8.00065 15.1668ZM8.00065 1.8335C4.60065 1.8335 1.83398 4.60016 1.83398 8.00016C1.83398 11.4002 4.60065 14.1668 8.00065 14.1668C11.4007 14.1668 14.1673 11.4002 14.1673 8.00016C14.1673 4.60016 11.4007 1.8335 8.00065 1.8335Z"
			fill="#E03137"
		/>
		<path
			d="M8 9.16683C7.72667 9.16683 7.5 8.94016 7.5 8.66683V5.3335C7.5 5.06016 7.72667 4.8335 8 4.8335C8.27333 4.8335 8.5 5.06016 8.5 5.3335V8.66683C8.5 8.94016 8.27333 9.16683 8 9.16683Z"
			fill="#E03137"
		/>
		<path
			d="M8.00065 11.3334C7.91398 11.3334 7.82732 11.3134 7.74732 11.2801C7.66732 11.2468 7.59398 11.2001 7.52732 11.1401C7.46732 11.0734 7.42065 11.0068 7.38732 10.9201C7.35398 10.8401 7.33398 10.7534 7.33398 10.6668C7.33398 10.5801 7.35398 10.4934 7.38732 10.4134C7.42065 10.3334 7.46732 10.2601 7.52732 10.1934C7.59398 10.1334 7.66732 10.0868 7.74732 10.0534C7.90732 9.98675 8.09398 9.98675 8.25398 10.0534C8.33398 10.0868 8.40732 10.1334 8.47398 10.1934C8.53398 10.2601 8.58065 10.3334 8.61398 10.4134C8.64732 10.4934 8.66732 10.5801 8.66732 10.6668C8.66732 10.7534 8.64732 10.8401 8.61398 10.9201C8.58065 11.0068 8.53398 11.0734 8.47398 11.1401C8.40732 11.2001 8.33398 11.2468 8.25398 11.2801C8.17398 11.3134 8.08732 11.3334 8.00065 11.3334Z"
			fill="#E03137"
		/>
	</svg>
);
