import React, { type Ref, forwardRef } from "react";
import type { SVGProps } from "react";

export const DiamondIcon = forwardRef(
	(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={32}
				height={32}
				fill="none"
				viewBox="0 0 32 32"
				className={`icon ${props.className || ""}`}
				ref={ref}
				{...props}
			>
				<path
					stroke="currentColor"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1.5}
					d="M15.293 3.207 3.207 15.293a1 1 0 0 0 0 1.414l12.086 12.086a1 1 0 0 0 1.414 0l12.086-12.086a1 1 0 0 0 0-1.414L16.707 3.207a1 1 0 0 0-1.414 0"
				/>
			</svg>
		);
	},
);
