export const checkIsUserAdmin = (user: any) => {
	return (
		user && Array.isArray(user.authRoles) && user.authRoles.includes("admin")
	);
};

export const checkIsUserCurator = (user: any) => {
	return (
		user && Array.isArray(user.authRoles) && user.authRoles.includes("curator")
	);
};
