import React, { type Ref, forwardRef } from "react";
import type { SVGProps } from "react";

export const GalleryIcon = forwardRef(
	(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={32}
				height={32}
				fill="none"
				viewBox="0 0 32 32"
				className={`icon ${props.className || ""}`}
				ref={ref}
				{...props}
			>
				<path
					stroke="currentColor"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={1.5}
					d="M12.4 28h7.2c6 0 8.4-2.4 8.4-8.4v-7.2c0-6-2.4-8.4-8.4-8.4h-7.2C6.4 4 4 6.4 4 12.4v7.2c0 6 2.4 8.4 8.4 8.4M14 4l6 23.833M16.707 16 4 19.707"
				/>
			</svg>
		);
	},
);
