import React, { useEffect, useState } from "react";
import Select from "../Select";

export const dateToString = (d: Date) => {
	const date = new Date(d);
	let day = date.getDate().toString();
	const year = date.getFullYear();
	const month = date.getMonth();

	const lastDigit = +day % 10;
	if (lastDigit === 1) {
		day += "st";
	} else if (lastDigit === 2) {
		day += "nd";
	} else if (lastDigit === 3) {
		day += "rd";
	} else {
		day += "th";
	}

	return `${fullMonthNames[month]} ${day}, ${year}`;
};

const fullMonthNames = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

const shortMonthNames = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec",
];

type TDate = Date | null | undefined;

const getDay = (date: TDate) => (date ? date.getDate().toString() : "");
const getMonth = (date: TDate) =>
	date ? shortMonthNames[date.getMonth()] : "";
const getYear = (date: TDate) => (date ? date.getFullYear().toString() : "");

export class DatePicker {
	static Simple = (props: {
		date?: Date | null;
		cb: (d: Date | null) => void;

		// Refactor when loading state will be change modal wide
		isLoading?: boolean;
	}) => {
		const { date, cb, isLoading } = props;

		const [d, setD] = useState(getDay(date));
		const [m, setM] = useState(getMonth(date));
		const [y, setY] = useState(getYear(date));
		const [editing, setEditing] = useState(false);

		useEffect(() => {
			setD(getDay(date));
			setM(getMonth(date));
			setY(getYear(date));
		}, [date]);

		useEffect(() => {
			if (d && m && y && !editing) {
				const month = shortMonthNames.findIndex((v) => v === m);
				const nd = new Date(+y, month, +d);
				cb(nd);
			}
		}, [d, m, y, editing]);

		const handleChangeDay = (e: React.ChangeEvent<HTMLInputElement>) => {
			const value = parseInt(e.target.value);

			if (!value) {
				setD("");
			} else if (isNaN(value) || value > 31 || value <= 0) {
				return;
			} else {
				setD(value.toString());
			}
		};

		const handleChangeMonth = (e: React.ChangeEvent<HTMLInputElement>) => {
			if (e.target.value === "") {
				cb(null);
			}
			setM(e.target.value);
		};

		const handleChangeYear = (e: React.ChangeEvent<HTMLInputElement>) => {
			const value = parseInt(e.target.value);
			if (e.target.value.length > 3) {
				setEditing(false);
			}
			if (!value) {
				setY("");
			} else if (isNaN(value) || value > new Date().getFullYear()) {
				return;
			} else {
				setY(value.toString());
			}
		};

		return (
			<div className="row">
				<div className="col col-sm-12 col-md-4">
					<input
						name="day"
						value={d}
						className="form-control"
						onChange={handleChangeDay}
						placeholder="Day"
						onFocus={() => setEditing(true)}
						onBlur={() => setEditing(false)}
					/>
				</div>

				<div className="col col-sm-12 col-md-4">
					<style>
						{`
              .datepicker-month-select:after {
                display: none;
              }
            `}
					</style>
					<Select
						value={m}
						noLabel
						onChange={handleChangeMonth}
						options={shortMonthNames}
						className="datepicker-month-select"
						placeholder="Month"
					/>
				</div>

				<div className="col col-sm-12 col-md-4">
					<input
						name="text"
						value={y}
						className="form-control"
						onChange={handleChangeYear}
						placeholder="Year"
						onFocus={() => setEditing(true)}
						onBlur={() => setEditing(false)}
					/>
				</div>
			</div>
		);
	};
}
