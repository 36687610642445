import { useCallback, useState } from "react";
import { type TValidationSchema, validationSchema } from "../utils/validation";

const noop = {};

interface IFormOptions<TValues> {
	onSubmit(values: TValues): void;
	initialValues: TValues;
	validationSchema?: TValidationSchema<TValues>;
}

export interface TFieldProps<TValues> {
	name: keyof TValues;
	value: any;
	error?: string;
	checked?: boolean;
	onChange?(event: React.ChangeEvent<any>): void;
}

export type TErrors<TValues> = {
	[key in keyof TValues]?: string;
};

export type FSetFieldValue<TValues> = (name: keyof TValues, value: any) => void;

export type FGetFieldProps<TValues> = (
	name: keyof TValues,
	options?: { type?: "checkbox" },
) => TFieldProps<TValues>;

export function useForm<TValues>(options: IFormOptions<TValues>) {
	const [values, setValues] = useState<TValues>(options.initialValues);
	const [errors, setErrors] = useState<TErrors<TValues> | null>(null);

	const validate = useCallback(
		(values: TValues) => {
			if (!options.validationSchema) {
				return true;
			}

			const validate = validationSchema(options.validationSchema);

			const errors = validate(values);
			setErrors(errors);

			return Object.values(errors).every((field) => !field);
		},
		[options.validationSchema],
	);

	const onSubmit = useCallback(
		(event?: React.FormEvent<HTMLFormElement>) => {
			event?.preventDefault();

			const isValid = validate(values);
			if (isValid) {
				options.onSubmit(values);
			}
		},
		[values, options, validate],
	);

	const setFieldValue: FSetFieldValue<TValues> = useCallback(
		(name, value) => {
			setValues((values) => {
				const newValues = { ...values, [name]: value };
				errors && validate(newValues);
				return newValues;
			});
		},
		[errors, validate],
	);

	const getFieldProps: FGetFieldProps<TValues> = useCallback(
		(name, options) => {
			const fieldProps: TFieldProps<TValues> = {
				name: name,
				value: values[name],
				error: errors?.[name],
				onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
					setFieldValue(name, event.target.value);
				},
			};

			if (options?.type === "checkbox") {
				fieldProps.checked = fieldProps.value === true;
				fieldProps.onChange = (event: React.ChangeEvent<HTMLInputElement>) =>
					setFieldValue(name, event.target.checked);
			}

			return fieldProps;
		},
		[errors, values, setFieldValue],
	);

	const setFielsValues = useCallback((values: Partial<TValues>) => {
		setValues((v) => ({ ...v, ...values }));
	}, []);

	const resetForm = useCallback(() => {
		setValues(options.initialValues);
	}, [options.initialValues]);

	return {
		values,
		errors: errors || noop,
		validate,
		onSubmit,
		setValues,
		resetForm,
		setFieldValue,
		getFieldProps,
		setFielsValues,
	};
}
