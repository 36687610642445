import React from "react";

export interface ILogoProps {
	className?: string;
}

export const Logo: React.FC<ILogoProps> = ({ className = "" }) => {
	return (
		<svg
			width="170"
			height="56"
			viewBox="0 0 170 56"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M4.43537 32.7755L1.52381 39.5986H0L9.80272 16.4082H11.4558L22.2585 39.5986H19.0476L15.8639 32.7755H4.43537ZM9.96599 20.1225H9.83673L5.06803 31.2857H15.1701L9.96599 20.1225Z"
				fill="currentColor"
			/>
			<path
				d="M48.4354 16.4082V40H48.2653L29.8775 21.8776H29.7483V39.6054H28.2245V16.0136H28.3946L46.7823 34.1361H46.9116V16.4082H48.4354Z"
				fill="currentColor"
			/>
			<path
				d="M58.8367 32.7755L55.9252 39.5986H54.4014L64.2109 16.4082H65.8639L76.6667 39.5986H73.4558L70.2721 32.7755H58.8367ZM64.3673 20.1225H64.2381L59.4694 31.2857H69.5714L64.3673 20.1225Z"
				fill="currentColor"
			/>
			<path
				d="M82.1292 33.7347C82.1497 34.1088 82.2381 34.5714 82.3946 35.1088C82.551 35.6531 82.8503 36.1769 83.2925 36.6803C83.7347 37.1905 84.3741 37.6259 85.2109 37.9864C86.0476 38.3537 87.1633 38.5306 88.5578 38.5306C90.2993 38.5306 91.7007 38.1973 92.7483 37.5374C93.7959 36.8776 94.3265 35.8231 94.3265 34.3878C94.3265 33.2381 93.9932 32.3265 93.3333 31.6395C92.6735 30.9524 91.8367 30.3742 90.8299 29.898C89.8231 29.4218 88.7347 29 87.5646 28.619C86.3946 28.2449 85.3061 27.7891 84.2993 27.2585C83.2925 26.7279 82.4626 26.068 81.7959 25.2721C81.1292 24.4762 80.8027 23.4286 80.8027 22.1224C80.8027 21.1293 81.0068 20.2449 81.415 19.4694C81.8231 18.6939 82.3741 18.0544 83.0748 17.551C83.7687 17.0408 84.5782 16.6531 85.4966 16.3946C86.415 16.1293 87.3809 16 88.4013 16C89.4218 16 90.3129 16.1156 91.2041 16.3469C92.0952 16.5782 92.9048 16.9252 93.6054 17.3741C94.3129 17.8299 94.8979 18.381 95.3673 19.0272C95.8299 19.6803 96.1292 20.415 96.2653 21.2313H94.0068C93.8299 19.9252 93.2381 18.9728 92.2313 18.3673C91.2245 17.7619 90 17.4558 88.5646 17.4558C87.7007 17.4558 86.9388 17.5646 86.2721 17.7687C85.6054 17.9796 85.0476 18.2585 84.5918 18.5986C84.136 18.9388 83.7959 19.3469 83.5646 19.8095C83.3333 20.2721 83.2177 20.7687 83.2177 21.2993C83.2177 22.2517 83.5578 23.0136 84.2449 23.585C84.932 24.1565 85.7891 24.6667 86.8163 25.1088C87.8435 25.551 88.966 25.9728 90.1701 26.3673C91.3741 26.7687 92.4898 27.2585 93.5238 27.8435C94.5578 28.4286 95.415 29.1565 96.0952 30.0272C96.7823 30.898 97.1224 32.0204 97.1224 33.3878C97.1224 34.381 96.9252 35.2857 96.5238 36.102C96.1224 36.9184 95.5578 37.6122 94.8299 38.1905C94.0952 38.7687 93.2177 39.2041 92.1905 39.517C91.1633 39.8231 90.0068 39.9796 88.7211 39.9796C87.4354 39.9796 86.2993 39.8299 85.2313 39.5306C84.1701 39.2313 83.2449 38.8095 82.4558 38.2721C81.6735 37.7347 81.0544 37.0748 80.5986 36.2993C80.1428 35.5238 79.8979 34.6667 79.8571 33.7143H82.1088L82.1292 33.7347Z"
				fill="currentColor"
			/>
			<path
				d="M105.782 32.7755L102.871 39.5986H101.347L111.156 16.4082H112.81L123.612 39.5986H120.401L117.218 32.7755H105.782ZM111.313 20.1225H111.184L106.415 31.2857H116.517L111.313 20.1225Z"
				fill="currentColor"
			/>
			<path
				d="M144.551 16.4082V18.2313H132.592V26.5442H142.864V28.068H132.592V37.7755H144.551V39.5986H129.578V16.4082H144.551Z"
				fill="currentColor"
			/>
			<path
				d="M151.973 32.7755L149.061 39.5986H147.537L157.347 16.4082H159L169.803 39.5986H166.592L163.408 32.7755H151.973ZM157.503 20.1225H157.374L152.605 31.2857H162.707L157.503 20.1225Z"
				fill="currentColor"
			/>
		</svg>
	);
};
