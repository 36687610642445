import React, { useMemo } from "react";
import Form from "react-bootstrap/esm/Form";
import ReactSelect from "react-select";
import { CaretDownIcon } from "../svg/icons/CaretDown";

export type TNewSelectOption<V = string> = {
	value: V;
	label: string;
};

export type TNewSelectChangeEvent<V = string> =
	React.ChangeEvent<HTMLInputElement> & {
		target: { target: { value: V } };
	};

export interface INewSelectProps<V = string> {
	value?: V;
	label?: string;
	options: TNewSelectOption<V>[];
	onChange?: (e: TNewSelectChangeEvent<V>) => void;
	className?: string;
	size?: "sm" | "md" | "lg";
	disabled?: boolean;
}

export function NewSelect<V = string>({
	className = "",
	label,
	size,
	onChange,
	value,
	options,
	disabled,
}: INewSelectProps<V>) {
	const innerValue = useMemo(
		() => options.find((o) => o.value === value),
		[value, options],
	);

	const handleChange = (selectedOptionObject: TNewSelectOption<V>) => {
		onChange &&
			onChange({
				target: { value: selectedOptionObject.value },
			} as TNewSelectChangeEvent<V>);
	};

	const selectComponents = useMemo(
		() => ({
			IndicatorSeparator: () => null,
			DropdownIndicator: (dropdownIndicatorProps) => {
				return (
					<div {...dropdownIndicatorProps}>
						<CaretDownIcon
							width={28}
							height={28}
							className="text-color-neutral900"
						/>
					</div>
				);
			},
		}),
		[],
	);

	const menuPortalTarget = useMemo(() => document.querySelector("body"), []);
	const classNames = useMemo(
		() => ({
			menuPortal: () => "zidx5000",
			input: () => "tbodymd",
			menu: () => "p-s10 border-radius-s20 newdropdown-menu",
			menuList: () => "tbodymd scrollbar",
			valueContainer: () => "tbodymd",
			control: (state) =>
				`tbodymd newdropdown-control border-radius-s30 border-1 border-color-${state.isFocused ? "primary700 input-box-shadow" : "neutral300"} ${size === "lg" ? "py-s11 px-s10" : size === "md" ? "py-s8 px-s10" : "py-s11 px-s10"} cursor-pointer`,
			indicatorsContainer: () => "me-s10",
			option: (props) => {
				return `tbodymd cursor-pointer newdropdown-item ${props.isSelected ? "newdropdown-item-selected" : ""}`;
			},
		}),
		[size],
	);

	return (
		<div className={className}>
			{label && (
				<Form.Label
					className="fs-fs14 fw-medium mb-s10 form-label"
					style={{ lineHeight: "160%" }}
				>
					{label}
				</Form.Label>
			)}

			<ReactSelect
				options={options}
				components={selectComponents}
				menuPortalTarget={menuPortalTarget}
				classNames={classNames}
				onChange={handleChange}
				value={innerValue}
				isDisabled={disabled}
			/>
		</div>
	);
}
