import React from "react";

export interface IIconBgProps {
	className?: string;
}

export const IconBg: React.FC<IIconBgProps> = ({ className }) => {
	return (
		<svg
			width="54"
			height="53"
			viewBox="0 0 54 53"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={className}
		>
			<path
				d="M52.4598 33.406C52.0512 35.1732 51.5064 36.8044 50.8254 38.4356C50.6892 38.8434 50.553 39.1153 50.2806 39.5231C47.9652 44.009 44.2878 47.6792 39.7932 49.8542C39.2484 50.1261 38.7036 50.3979 38.295 50.5339C33.9366 52.301 29.0334 52.9807 24.1302 51.8932C11.1912 49.1745 2.88303 36.5325 5.60703 23.6186C8.33103 10.7048 20.9976 2.41271 33.9366 5.13142C38.5674 6.08297 42.6534 8.39387 46.0584 11.6563C51.7788 17.2297 54.2304 25.5217 52.4598 33.406Z"
				fill="black"
				fillOpacity="0.05"
			/>
			<path
				d="M49.7967 26.1404C49.7967 28.0391 49.5437 29.9378 49.1643 31.71C48.9113 32.8492 48.5319 33.8619 48.1525 34.8746C45.7493 41.2037 40.6902 46.267 34.3662 48.6721C33.8603 48.7986 33.4808 49.0518 32.9749 49.1784C30.5718 49.9379 28.1687 50.3176 25.5126 50.3176C12.1058 50.3176 1.22852 39.4315 1.22852 26.0138C1.22852 12.596 12.1058 1.70996 25.5126 1.70996C38.9194 1.96313 49.7967 12.7226 49.7967 26.1404Z"
				fill="white"
			/>
			<path
				d="M49.7967 27.406C49.7967 29.3047 49.5437 31.2035 49.1643 32.9756C48.9113 34.1149 48.5319 35.1275 48.1525 36.1402C45.7493 42.4693 40.6902 47.5326 34.3662 49.9377C33.8603 50.0643 33.4808 50.3174 32.9749 50.444C30.5718 51.2035 28.1687 51.5833 25.5126 51.5833C12.1058 51.5833 1.22852 40.6972 1.22852 27.2794C1.22852 13.8617 12.1058 2.97559 25.5126 2.97559C38.9194 3.22875 49.7967 13.9883 49.7967 27.406Z"
				fill="currentColor"
			/>
			<path
				d="M36.8948 47.1534C36.0095 47.6597 34.9976 48.0395 34.1123 48.4192C33.6064 48.5458 33.2269 48.799 32.721 48.9256C30.3179 49.6851 27.9148 50.0648 25.2587 50.0648C11.8519 50.0648 0.974609 39.1787 0.974609 25.761C0.974609 16.5205 6.03379 8.54576 13.6226 4.49512"
				stroke="#020202"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M22.0977 1.83666C23.1095 1.71008 24.2478 1.5835 25.2596 1.5835C38.6665 1.5835 49.5437 12.4696 49.5437 25.8873C49.5437 27.7861 49.2908 29.6848 48.9113 31.457C48.6584 32.5962 48.2789 33.6089 47.8995 34.6215C46.8877 37.1532 45.4964 39.5582 43.7257 41.7101"
				stroke="#020202"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17.291 2.97463C17.4175 2.97463 17.6705 2.84814 17.7969 2.84814"
				stroke="#020202"
				strokeWidth="1.5"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
