import React, {
	useEffect,
	useState,
	Fragment,
	useRef,
	useCallback,
} from "react";

import Dropdown from "react-bootstrap/esm/Dropdown";
import Form from "react-bootstrap/esm/Form";
import Image from "react-bootstrap/esm/Image";
import InputGroup from "react-bootstrap/esm/InputGroup";

import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import Spinner from "react-bootstrap/esm/Spinner";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
	gaEventSearchFinished,
	gaEventSearchStarted,
} from "../actions/google-analytics";
import { v2api } from "../actions/v2api";
import { useDebouncedCallback } from "../hooks/useDebounce";
import {
	getLinkIdentifierForArtist,
	getLinkIdentifierForGallery,
} from "../utils";
import { PLACEHOLDER } from "../utils/const";
import { CaretRightIcon } from "./svg/icons/CaretRight";
import { CloseIcon } from "./svg/icons/Close";
import { SearchIcon } from "./svg/icons/Search";

const SearchAll = () => {
	const [txt, setTxt] = useState("");
	const [showResults, setShow] = useState(false);
	const [focused, setFocused] = useState(false);
	const navigate = useNavigate();
	const serchInput = useRef();
	const [loading, setLoading] = useState(false);

	const [exhibitions, setExhibitions] = useState([]);
	const [artists, setArtists] = useState([]);
	const [galleries, setGalleries] = useState([]);
	const [exhibitionsCount, setExhibitionsCount] = useState(0);
	const [artistsCount, setArtistsCount] = useState(0);
	const [galleriesCount, setGalleriesCount] = useState(0);
	// exhibitions, artists, galleries, exhibitionsCount, artistsCount, galleriesCount

	useEffect(() => {
		const handleScroll = (_e: Event) => {
			setShow(false);
			serchInput.current.blur();
		};
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const makeSearch = () => {
		navigate("/search", { state: { query: txt } });
	};
	const makeSearchArtists = () => {
		navigate("/artists", { state: { query: txt } });
	};
	const makeSearchGalleries = () => {
		navigate("/exhibitions", { state: { query: txt } });
	};

	const trachSearchFinished = useDebouncedCallback((searchText) => {
		gaEventSearchFinished(searchText);
	}, 1000);

	const onSuccessSearch = useCallback((searchText) => {
		trachSearchFinished(searchText);
		// do not insert debouncedCallback as dependency
	}, []);

	// const { loading, ...result } = useGalleriesByArtist(txt, onSuccessSearch);
	const queryParams = { limit: 2, sort: { updatedAt: -1 } };
	useEffect(() => {
		setLoading(true);
		if (txt.length >= 2) {
			v2api(
				"/exhibition/search",
				{ search: txt, queryParams },
				(err, result) => {
					console.log("1err, res", err, result);
					setExhibitions(result.exhibitions);
					setArtists(result.artists);
					setGalleries(result.galleries);
					setExhibitionsCount(result.exhibitionsCount);
					setArtistsCount(result.artistsCount);
					setGalleriesCount(result.galleriesCount);
					setLoading(false);
				},
			);
		}
	}, [txt]);
	// useEffect(() => {
	// 	if (!loading) {
	// 		setExhibitions(result.exhibitions);
	// 		setArtists(result.artists);
	// 		setGalleries(result.galleries);
	// 		setExhibitionsCount(result.exhibitionsCount);
	// 		setArtistsCount(result.artistsCount);
	// 		setGalleriesCount(result.galleriesCount);
	// 	}
	// }, [result, loading]);

	useEffect(() => {
		const show = txt.length > 1;
		const resultLength = exhibitions.length + artists.length + galleries.length;
		const nothingToShow = resultLength > 0; // ? !loading : true;
		setShow(show && nothingToShow);
	}, [txt, focused, loading, artists]);

	const getArtistImage = (artist) => {
		return artist.picture?.thumb.url || artist.pictureURL || PLACEHOLDER.artist;
	};

	const handleBlur = () => {
		setTimeout(() => setFocused(false), 100);
	};

	const handleSearchChange = (e) => {
		setTxt(e.target.value);
	};
	const getGalName = (gal) => {
		return gal.galleryData.name
			? gal.galleryData.name
			: `${gal.firstName} ${gal.lastName ? gal.lastName : ""}`;
	};
	const getGalImage = (gal) => {
		return (
			gal.galleryData.picture?.thumb.url ||
			gal.galleryData.pictureURL ||
			PLACEHOLDER.artist
		);
	};

	return (
		<Fragment>
			<Dropdown className="mx-md-4 mx-sm-0 w-100 h-100">
				<InputGroup className="mt-0 search-input h-100">
					<Form.Control
						type="text"
						value={txt}
						className="h-100"
						ref={serchInput}
						onChange={handleSearchChange}
						onBlur={handleBlur}
						onFocus={() => {
							setFocused(true);
						}}
						onClick={() => {
							gaEventSearchStarted();
						}}
						placeholder="Search by artist name"
					/>
					<InputGroup.Text
						id="basic-addon"
						className={((focused && "focused ") || "") + " pe-2 h-100"}
					>
						{txt.length > 0 ? (
							loading ? (
								<Spinner variant="light" size="sm" />
							) : (
								<CloseIcon
									width={24}
									height={24}
									onClick={() => setTxt("")}
									role="button"
								/>
							)
						) : (
							<SearchIcon width={20} height={20} />
						)}
					</InputGroup.Text>
				</InputGroup>
				<Dropdown.Menu
					show={showResults}
					className={
						"w-100 search-input__results p-4 mt-2 " +
						(showResults ? " show" : "")
					}
				>
					{exhibitions.length > 0 ? (
						<div
							className={
								"search-input__results__section" +
								(artists.length + galleries.length === 0 ? " pb-3" : "")
							}
						>
							<div className="search-input__results__section-title d-flex justify-content-between mb-3">
								<span>Exhibitions ({exhibitionsCount})</span>
								<button
									onClick={makeSearchGalleries}
									className="border-0 bg-transparent text-color-neutral500"
								>
									<CaretRightIcon height="14" />
								</button>
							</div>
							{exhibitions.slice(0, 2).map((gal) => {
								if (!gal || !gal["_id"]) {
									console.warn("Broken gallery", gal);
									return null;
								}

								return (
									<Link
										key={gal._id}
										to={{
											pathname: `/viewExhibition/${gal._id}`,
											state: { internalRef: true },
										}}
										className="d-flex flex-row w-100 align-items-center my-2"
										style={{ maxWidth: "100%" }}
									>
										<Image
											src={gal.thumbnail?.url || gal.preview?.url}
											width="60"
											height="60"
										/>
										<div className="d-flex flex-column ms-3 justify-content-evenly text-truncate">
											<span className="search-input__results__artist-name d-block text-truncate">
												{gal.name ||
													`${gal.artistName ? gal.artistName : gal.galleryName} Gallery`}{" "}
											</span>
											<span className="search-input__results__artist-artworks d-block text-truncate">
												{gal.artistName ? gal.artistName : gal.galleryName}
												&nbsp;
											</span>
										</div>
									</Link>
								);
							})}
							{exhibitions.length == 0 ? (
								<div className="tbodymd text-color-neutral600">
									No results match your search.
								</div>
							) : (
								""
							)}
						</div>
					) : (
						""
					)}
					{galleries.length > 0 ? (
						<div
							className={
								"search-input__results__section " +
								(exhibitions.length > 0 ? "mt-s24" : "") +
								(artists.length === 0 ? " pb-3" : "")
							}
						>
							<div className="search-input__results__section-title d-flex justify-content-between mb-3">
								<span>Galleries ({galleriesCount})</span>
								<button
									onClick={() => {}}
									className="border-0 bg-transparent  text-color-neutral500 d-none"
								>
									<CaretRightIcon height="14" />
								</button>
							</div>
							{galleries.slice(0, 2).map((gal) => {
								if (!gal || !gal["_id"]) {
									console.warn("Broken gallery", gal);
									return null;
								}

								return (
									<Link
										to={`/gallery/${getLinkIdentifierForGallery(gal)}`}
										key={gal._id}
										className="d-flex flex-row w-100 align-items-center my-2"
									>
										<LazyLoadImage
											className="rounded-circle"
											width={40}
											height={40}
											src={getGalImage(gal)}
											effect="opacity"
											wrapperClassName="me-s12"
											useIntersectionObserver
										/>
										<div className="d-flex flex-column ms-3 justify-content-evenly">
											<span className="search-input__results__artist-name">
												{getGalName(gal)}
											</span>
											<span className="search-input__results__artist-artworks text-inactive">
												{gal.country}&nbsp;
											</span>
										</div>
									</Link>
								);
							})}
							{galleries.length == 0 ? (
								<div className="tbodymd text-color-neutral600">
									No results match your search.
								</div>
							) : (
								""
							)}
						</div>
					) : (
						""
					)}
					{artists.length > 0 ? (
						<div
							className={
								"search-input__results__section pb-3 " +
								(exhibitions.length + galleries.length > 0 ? "mt-s24" : "")
							}
						>
							<div className="search-input__results__section-title d-flex justify-content-between mb-3">
								<span>Artists ({artistsCount})</span>
								<button
									onClick={makeSearchArtists}
									className="border-0 bg-transparent text-color-neutral500"
								>
									<CaretRightIcon height="14" />
								</button>
							</div>
							{artists.slice(0, 2).map((artist) => (
								<Link
									to={`/artist/${getLinkIdentifierForArtist(artist)}`}
									key={artist._id}
									className="d-flex flex-row w-100 align-items-center my-2"
								>
									<Image
										src={getArtistImage(artist)}
										roundedCircle
										width="40"
									/>
									<div className="d-flex flex-column ms-3 justify-content-evenly">
										<span className="search-input__results__artist-name">
											{artist.firstName} {artist.lastName}
										</span>
										<span className="search-input__results__artist-artworks text-inactive">
											{artist.artWorksCount} Artworks
										</span>
									</div>
								</Link>
							))}
							{artists.length == 0 ? (
								<div className="tbodymd text-color-neutral600">
									No results match your search.
								</div>
							) : (
								""
							)}
						</div>
					) : (
						""
					)}

					<button
						className="w-100 btn btn-secondary mt-s8 btn-md"
						onClick={() => makeSearch()}
					>
						Show all result
					</button>
				</Dropdown.Menu>
			</Dropdown>
		</Fragment>
	);
};

export default SearchAll;
