import React, { type Ref, forwardRef } from "react";
import type { SVGProps } from "react";

export const WarningCircleIcon = forwardRef(
	(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={32}
				height={32}
				fill="none"
				viewBox="0 0 32 32"
				className={`icon ${props.className || ""}`}
				ref={ref}
				{...props}
			>
				<path
					stroke="currentColor"
					strokeMiterlimit={10}
					strokeWidth={props["strokeWidth"] ? props["strokeWidth"] : 1.5}
					d="M16 28c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12Z"
				/>
				<path
					stroke="currentColor"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={props["strokeWidth"] ? props["strokeWidth"] : 1.5}
					d="M16 10v7"
				/>
				<path
					fill="currentColor"
					d="M16 23a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"
				/>
			</svg>
		);
	},
);
