import React from "react";
import { useNavigate } from "react-router-dom";
import { v2api } from "../actions/v2api";
import { useCurrentUserContext } from "../contexts/CurrentUser";
import { useToastContext } from "../contexts/Toast";
import { LSKEY_LOGIN_EXPIRES, LSKEY_LOGIN_TOKEN } from "../utils/const";
import { LSKEY_USER_ID } from "./../utils/const";

export const useLogout = ({ postLogoutRedirectHref } = {}) => {
	const toast = useToastContext();
	const { clear } = useCurrentUserContext();
	const navigate = useNavigate();

	return React.useCallback(
		(e: React.MouseEvent | undefined | null) => {
			if (e) e.preventDefault();
			v2api("/users/logout", null, (err, res) => {
				if (err) {
					console.error(err, res);
					toast.error("Error logging out");
				} else {
					window.localStorage.removeItem(LSKEY_LOGIN_TOKEN);
					window.localStorage.removeItem(LSKEY_LOGIN_EXPIRES);
					window.localStorage.removeItem(LSKEY_USER_ID);
					clear();
					navigate(postLogoutRedirectHref || "/");
				}
			});
		},
		[history, postLogoutRedirectHref],
	);
};
