import React from "react";

export interface IAnasaeaLogoProps {
	className?: string;
	width?: number | string;
	height?: number | string;
}

export function AnasaeaLogo({
	className = "",
	width = 143,
	height = 21,
}: IAnasaeaLogoProps) {
	return (
		<svg
			className={`${className}`}
			width={width}
			height={height}
			viewBox="0 0 143 21"
			fill="none"
		>
			<path
				fill="currentColor"
				d="M4.445 14.48 2.02 20.166H.749L8.918.84h1.378l9.002 19.326h-2.676l-2.653-5.686H4.445ZM9.054 3.935h-.108l-3.974 9.303h8.419L9.054 3.935ZM41.112.84V20.5h-.142L25.647 5.398h-.108v14.773h-1.27V.511h.142l15.323 15.102h.108V.84h1.27ZM49.78 14.48l-2.427 5.686h-1.27L54.258.84h1.378l9.002 19.326h-2.676L59.31 14.48h-9.53Zm4.608-10.545h-.107l-3.974 9.303h8.418l-4.337-9.303ZM69.19 15.279c.017.312.09.697.221 1.145.13.454.38.89.748 1.31.369.425.902.787 1.6 1.088.696.306 1.626.454 2.788.454 1.452 0 2.62-.278 3.492-.828.873-.55 1.316-1.429 1.316-2.625 0-.958-.278-1.718-.828-2.29-.55-.573-1.247-1.054-2.086-1.451a23.047 23.047 0 0 0-2.722-1.066A16.799 16.799 0 0 1 71 9.882c-.84-.442-1.531-.992-2.087-1.655-.555-.664-.827-1.537-.827-2.625 0-.828.17-1.565.51-2.21.34-.647.8-1.18 1.383-1.6A6.225 6.225 0 0 1 71.996.83 8.67 8.67 0 0 1 74.416.5c.851 0 1.594.096 2.336.29a6.927 6.927 0 0 1 2.002.855c.59.38 1.077.84 1.468 1.378a4.38 4.38 0 0 1 .748 1.836h-1.882c-.147-1.088-.64-1.882-1.48-2.386-.839-.505-1.859-.76-3.055-.76-.72 0-1.355.09-1.91.26a4.682 4.682 0 0 0-1.4.693c-.38.283-.664.623-.857 1.009-.192.385-.289.799-.289 1.241 0 .794.284 1.429.856 1.905.573.476 1.287.901 2.143 1.27.856.368 1.791.72 2.795 1.048 1.003.335 1.933.743 2.794 1.23a7.143 7.143 0 0 1 2.143 1.82c.573.726.856 1.661.856 2.8 0 .829-.164 1.582-.498 2.263a5.04 5.04 0 0 1-1.412 1.74c-.612.482-1.344.845-2.2 1.105-.856.256-1.82.386-2.89.386-1.072 0-2.019-.125-2.909-.374-.884-.25-1.655-.601-2.313-1.049a5.146 5.146 0 0 1-1.547-1.644 4.557 4.557 0 0 1-.618-2.154h1.876l.017.017ZM88.9 14.48l-2.425 5.686h-1.27L93.379.84h1.378l9.002 19.326h-2.676L98.43 14.48H88.9Zm4.61-10.545h-.108l-3.974 9.303h8.419L93.51 3.935ZM121.208.84v1.52h-9.966v6.927h8.56v1.27h-8.56v8.09h9.966v1.519h-12.477V.84h12.477ZM127.393 14.48l-2.426 5.686h-1.27L131.871.84h1.378l9.002 19.326h-2.675l-2.654-5.686h-9.529Zm4.609-10.545h-.108l-3.974 9.303h8.419l-4.337-9.303Z"
			/>
		</svg>
	);
}
